import gql from "graphql-tag";

export const updateFlag = gql`
  mutation UpdateFlag ($input: UpdateFlagInput!) {
    updateFlag (input: $input)
  }`;


export const list = gql`
  {
    languages {
      code
      name
      local_name
    }
  }
`;
