import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../queries";
import {withRouter} from "react-router-dom";
import {apolloClient} from "../../config";
import BaseProjectSettings from "./BaseProjectSettings";
import {FormattedMessage} from "react-intl";
import confirmDialog from "../dialogs/ConfirmDialog";

class ProjectSettings extends React.Component  {

  deleteDialogConfirmationContent = (
    <FormattedMessage id={'project.delete.confirmation.dialog.message'} values={{br: <br/>}} />
  );

  duplicateDialogConfirmationContent = (
    <FormattedMessage id={'project.duplicate.confirmation.dialog.message'} values={{br: <br/>}} />
  );

  render() {
    const { projectId } = this.props.match.params;

    if(!projectId) return null;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { project } = data;

          return (
            <Mutation
              mutation={queries.projects.update}
              refetchQueries={() => [
                { query: queries.projects.getWithChildren, variables: {id: projectId} }
              ]}
            >
              {(updateProject) => (
                <Fragment>
                  <BaseProjectSettings
                    project={project}
                    onLanguageSelectorSettingsUpdate={(input) => {
                      updateProject({ variables:{ input: { id: project.id, ...input }}});
                    }}
                    onNameChange={(name) => {
                      this.setState({project_name: name})
                    }}
                    onNewColor={() => {
                      updateProject({ variables:{ input: {
                            id: project.id,
                            color_palette: project.color_palette.concat(['#000000'])
                          }}});
                    }}
                    onLanguagesUpdate={(languages) => {
                      updateProject({ variables:{ input: {id: project.id, language_codes: languages.map((lang) => lang.code)}}});
                    }}
                    onNewPrimaryLanguage={(languageCode) => {
                      updateProject({ variables:{ input: {id: project.id, primary_language_code: languageCode}}});
                    }}
                    onColorUpdate={(color, idx) => {
                      const newPalette = project.color_palette;
                      newPalette[idx] = color;
                      updateProject({ variables:{ input: {id: project.id, color_palette: newPalette}}});
                    }}
                    onNewFont={async ({name, uri}) => {
                      await apolloClient.mutate({
                        mutation: queries.fonts.create,
                        variables: {input: {
                            name,
                            uri,
                            project_id: project.id
                          }},
                        refetchQueries: () => [{ query: queries.projects.getWithChildren, variables: {id: project.id} }]
                      })
                    }}
                    onNewButtonsSettings={(newBtnSettings) => {
                      this.setState({
                        buttonSettings: {
                          default_button_text_color: newBtnSettings.textColor,
                          default_button_text_size: newBtnSettings.textSize,
                          default_button_font: newBtnSettings.font,
                          default_button_opacity: newBtnSettings.opacity,
                          default_button_shape: newBtnSettings.shape,
                          default_button_width_percentage: newBtnSettings.widthPercentage,
                          // default_button_fit_whole_cell: btnSettings,
                          default_button_background_type: newBtnSettings.buttonBackgroundType,
                          default_button_background_image_uri: newBtnSettings.buttonBackgroundImageUri,
                          default_button_background_color: newBtnSettings.buttonBackgroundColor,
                        }
                      });
                    }}
                  />
                  <div className={'tsk-bottom-buttons'}>
                    <button
                      className={'tsk-button'}
                      onClick={() => {
                      if(!!this.state) {
                        updateProject({ variables:{
                          input: {
                              id: project.id,
                              ...(this.state.project_name ? {name: this.state.project_name} : {}),
                              ...this.state.buttonSettings
                          }
                        }});
                      }
                    }}>
                      <FormattedMessage id={'common.button.submit'} />
                    </button>
                    &nbsp;
                    <button
                      className={'tsk-button tsk-warning'}
                      onClick={() => {
                        confirmDialog(this.duplicateDialogConfirmationContent).then(res => {
                          apolloClient.mutate({
                            mutation: queries.projects.duplicate,
                            variables: {input: projectId},
                            refetchQueries: [{query: queries.projects.list}]
                          }).then((res) => {
                            if(!res.errors && res.data) {
                              this.props.history.push(`/project/${res.data.duplicateProject.id}`);
                            }
                          });
                        });
                      }}>
                      <FormattedMessage id={'common.button.duplicate'} />
                    </button>
                    &nbsp;
                    <button
                      className={'tsk-button tsk-warning'}
                      onClick={() => {
                        confirmDialog(this.deleteDialogConfirmationContent).then(res => {
                          apolloClient.mutate({
                            mutation: queries.projects.del,
                            variables: {input: projectId},
                            refetchQueries: [{query: queries.projects.list}]
                          }).then(() => {
                            this.props.history.push(`/`);
                          });
                        });
                      }}>
                      <FormattedMessage id={'common.button.delete'} />
                    </button>
                  </div>
                </Fragment>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(ProjectSettings);
