import gql from "graphql-tag";

export const create = gql`
  mutation CreatePage ($input: CreatePageInput!) {
    createPage (input: $input) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
    }
  }`;

export const duplicate = gql`
  mutation DuplicatePage ($input: ID!) {
    duplicatePage (input: $input) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
    }
  }`;

export const del = gql`
    mutation DeletePage ($input: ID!) {
        deletePage (input: $input) {
            id
            name
            background_type
            background_image_uri
            background_video_uri
            background_color
        }
    }`;

export const get = gql`
  query Page($id: ID!) {
    page(id: $id) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
    }
  }
`;

export const update = gql`
  mutation UpdatePage ($input: UpdatePageInput!) {
    updatePage (input: $input) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
    }
  }
`;

export const getWithChildren = gql`
  query Page($id: ID!, $screen_type_id: ID!) {
    page(id: $id) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
      cells {
        id
        coordinates(screen_type_id: $screen_type_id) {
          x
          y
          width
          height
        }
        cell_type
        is_back_link
        links_to_page_id
        links_to_language
      }
    }
  }
`;
