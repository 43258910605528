import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {ImageCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class ImageCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={['far', 'image']} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.image.get}
        renderingCondition={(imageCell) => !!imageCell.image_uri}
      >
        {(imageCell) => <ImageCell preview={true} cell={imageCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default ImageCellPreview;
