import gql from "graphql-tag";

export default {
  updateI18n: gql`
      mutation UpdateI18nFormField ($input: FormFieldI18nInput!) {
          i18nFormField (input: $input) {
              language_code
              label
          }
      }`,

  update: gql`
      mutation UpdateFormField ($input: UpdateFormFieldInput!) {
          updateFormField (input: $input) {
              id
              type
              name
              label
              required
              sort_order
              half_width
              visible
          }
      }`,

  create: gql`
    mutation CreateFormField ($input: CreateFormFieldInput!) {
        createFormField (input: $input) {
            id
            type
            name
            label
            required
            sort_order
            half_width
            visible
        }
    }
  `,

  create_option: gql`
  mutation CreateFormFieldOption ($input: CreateFormFieldOptionInput!) {
      createFormFieldOption (input: $input) {
          name
          form_field_id
      }
  }
`,

    updateI18nForOption: gql`
        mutation UpdateFormFiedOptionI18n ($input: UpdateFormFieldOptionI18nInput!){
            updateFormFieldOptionI18n (input: $input){
                language_code
                label
            }
        }
    `
}
