import gql from "graphql-tag";

export const create = gql`
  mutation CreateFont ($input: CreateFontInput!) {
    createFont (input: $input) {
      id
      name
      uri
    }
  }`;

export const get = gql`
  query Font($id: ID!) {
    font(id: $id) {
      id
      name
      uri
    }
  }
`;
