import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';

import '../../style/ProjectsList.scss';
import queries from "../../queries";

class ProjectsList extends Component {

  render() {
    return (
      <Query query={queries.projects.list}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          return (
            <ul className='tsk-projects-list-container'>
              {data.projects.map((project) => {
                return (
                  <li key={project.id} className='tsk-projects-list-item'>
                    <Link to={`/project/${project.id}`}>
                      {project.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )
        }}
      </Query>
    );
  }
}

export default ProjectsList;
