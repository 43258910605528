import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queries from "../../queries";
import {FormattedMessage} from "react-intl";

import '../../style/ProjectCreation.scss';
import BaseProjectSettings from "./BaseProjectSettings";
import {apolloClient} from "../../config";

class ProjectCreation extends Component {

  state = {
    fontRequiredError: false,
    buttonBackgroundRequiredError: false,
    project: {
      name: '',
      languages: [],
      color_palette: ['#000000'],
      fonts: []
    }
  };

  render() {
    const {project, fontRequiredError, buttonBackgroundRequiredError, languageRequiredError, languageSelectorError} = this.state;

    document.fonts.clear();
    for (const font of project.fonts) {
      const fontFace = new FontFace(font.name, `url(${font.uri})`);
      fontFace.load().then((loadedFont) => {
        document.fonts.add(loadedFont);
      }).catch(function(error) {
        // error occurred
      });
    }

    const createProject = async () => {
      const errorState = {
        fontRequiredError: project.fonts.length === 0,
        buttonBackgroundRequiredError: !project.default_button_background_type,
        languageRequiredError: project.languages.length === 0,
        languageSelectorError: (!!project.languages && project.languages.length > 1) && (!project.language_selector_background_color || !project.language_selector_border_color  || ! project.language_selector_text_color),
      };
      this.setState(errorState);
      if(errorState.fontRequiredError || errorState.buttonBackgroundRequiredError || errorState.languageRequiredError || errorState.languageSelectorError) {
        return;
      }
      const projectData = {
        ...project,
        fonts: undefined,
        languages: undefined,
        language_codes: project.languages.map((lang) => lang.code),
        primary_language_code: project.primary_language_code || project.languages[0].code,
        default_button_fit_whole_cell: false, //TODO
      };

      const response = await apolloClient.mutate({
        mutation: queries.projects.create,
        variables: {input: projectData},
        refetchQueries: () => [{ query: queries.projects.list }]
      });

      const createdProject = response.data.createProject;

      await Promise.all(project.fonts.map((font) => (
        apolloClient.mutate({
          mutation: queries.fonts.create,
          variables: {input: {
              name: font.name,
              uri: font.uri,
              project_id: createdProject.id
            }},
          refetchQueries: () => [{ query: queries.projects.getWithChildren, variables: {id: createdProject.id} }]
        })
      )));

      this.props.history.push(`/project/${createdProject.id}`);
    };

    return (
      <div className={'tsk-project-creation-form'}>
        <div className={'tsk-form-content'}>
          <BaseProjectSettings
            project={project}
            onNameChange={(name) => {
              this.setState({project: {...project, name}})
            }}
            onNewColor={() => {
              project.color_palette.push('#000000');
              this.setState({project});
            }}
            onLanguageSelectorSettingsUpdate={(input) => {
              const {language_selector_background_color, language_selector_border_color, language_selector_text_color, language_selector_shape, language_selector_display_type} = input;
              if(language_selector_background_color)
                project.language_selector_background_color = language_selector_background_color;
              if(language_selector_border_color)
                project.language_selector_border_color = language_selector_border_color;
              if(language_selector_text_color)
                project.language_selector_text_color = language_selector_text_color;
              if(language_selector_shape)
                project.language_selector_shape = language_selector_shape;
              if(language_selector_display_type)
                project.language_selector_display_type = language_selector_display_type;
              this.setState({project});
            }}
            onLanguagesUpdate={(languages) => {
              project.languages = languages;
              this.setState({project});
            }}
            onNewPrimaryLanguage={(languageCode) => {
              project.primary_language_code = languageCode;
              this.setState({project});
            }}
            onColorUpdate={(color, idx) => {
              project.color_palette[idx] = color;
              this.setState({project});
            }}
            onNewFont={async ({name, uri}) => {
              project.fonts.push({ name, uri });
              this.setState({project, fontRequiredError: false});
            }}
            onNewButtonsSettings={(btnSettings) => {
              console.log('button settings changed: ', project);
              this.setState({
                project: {
                  ...project,
                  ...(btnSettings.textColor !== undefined ? {default_button_text_color: btnSettings.textColor}: {}),
                  ...(btnSettings.textSize !== undefined ? {default_button_text_size: btnSettings.textSize}: {}),
                  ...(btnSettings.font !== undefined ? {default_button_font: btnSettings.font}: {}),
                  ...(btnSettings.opacity !== undefined ? {default_button_opacity: btnSettings.opacity}: {}),
                  ...(btnSettings.shape !== undefined ? {default_button_shape: btnSettings.shape}: {}),
                  ...(btnSettings.widthPercentage !== undefined ? {default_button_width_percentage: btnSettings.widthPercentage}: {}),
                  ...(btnSettings.buttonBackgroundType !== undefined ? {default_button_background_type: btnSettings.buttonBackgroundType}: {}),
                  ...(btnSettings.buttonBackgroundImageUri !== undefined ? {default_button_background_image_uri: btnSettings.buttonBackgroundImageUri}: {}),
                  ...(btnSettings.buttonBackgroundColor !== undefined ? {default_button_background_color: btnSettings.buttonBackgroundColor}: {}),
                }
              });
            }}
          />
          <div className={'tsk-error'} style={{display: fontRequiredError? 'block': 'none'}}>
            <FormattedMessage id={'project.creation.font.required.error'} />
          </div>
          <div className={'tsk-error'} style={{display: buttonBackgroundRequiredError? 'block': 'none'}}>
            <FormattedMessage id={'project.creation.buttonbackground.required.error'} />
          </div>
          <div className={'tsk-error'} style={{display: languageRequiredError? 'block': 'none'}}>
            <FormattedMessage id={'project.creation.language.required.error'} />
          </div>
          <div className={'tsk-error'} style={{display: languageSelectorError? 'block': 'none'}}>
            <FormattedMessage id={'project.creation.languageselector.required.error'} />
          </div>
        </div>
        <div className={'tsk-form-bottom'}>
          <button className={'tsk-button'} type="button" onClick={createProject}>
            <FormattedMessage id={'project.creation.button'} />
          </button>
        </div>
      </div>
    )
  }
}

export default withRouter(ProjectCreation);
