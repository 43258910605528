import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import {FormattedMessage} from "react-intl";
import PaletteColorPicker from "../../PaletteColorPicker";
import {withRouter} from "react-router-dom";
import ProductsSortOrder from "./ProductsSortOrder";
import NeedAdmin from "../../NeedAdmin";

class ProductsListCellEditor extends React.Component  {

  render() {
    const { projectId } = this.props.match.params;
    const { cell } = this.props;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {(projectQueryResponse) => {
          if (projectQueryResponse.loading) return null;
          if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
          const {project} = projectQueryResponse.data;
          if(!project) return null;

          return (
            <Query query={queries.cells.products_list.get} variables={{ id: cell.id }}>
              {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return `Error!: ${error}`;

                const { productsListCell } = data;
                if (!productsListCell) return null;

                const knownFont = project.fonts.map(f => f.name).indexOf(productsListCell.font) >= 0;

                return (
                  <Mutation
                    mutation={queries.cells.products_list.update}
                    refetchQueries={() => [{ query: queries.cells.products_list.get, variables: {id: cell.id} }]}
                  >
                    {(mutationFct) => (
                      <Fragment>
                        <NeedAdmin>
                          <div className={'tsk-input-line'}>
                            <label><FormattedMessage id={`project.settings.languageselector.backgroundcolor.label`} /></label>
                            <PaletteColorPicker
                              color={productsListCell.background_color}
                              disableAlpha={true}
                              palette={project.color_palette}
                              onChange={(color) => {
                                const rgb = color.rgb;
                                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                                mutationFct({ variables: { input: { id: cell.id, background_color: colorStr } }})
                              }}
                            />
                          </div>

                          <div className={'tsk-input-line'}>
                            <label><FormattedMessage id={`project.settings.languageselector.textcolor.label`} /></label>
                            <PaletteColorPicker
                              color={productsListCell.text_color}
                              disableAlpha={true}
                              palette={project.color_palette}
                              onChange={(color) => {
                                const rgb = color.rgb;
                                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                                mutationFct({ variables: { input: { id: cell.id, text_color: colorStr } }})
                              }}
                            />
                          </div>

                          <div className={'tsk-input-line'}>
                            <label><FormattedMessage id={`cell.products.modal.backdrop.color`} /></label>
                            <PaletteColorPicker
                              color={productsListCell.modal_backdrop_color}
                              disableAlpha={false}
                              palette={project.color_palette}
                              onChange={(color) => {
                                const rgb = color.rgb;
                                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                                mutationFct({ variables: { input: { id: cell.id, modal_backdrop_color: colorStr } }})
                              }}
                            />
                          </div>

                          <div className={'tsk-input-line'}>
                            <label><FormattedMessage id={`cell.products.modal.background.color`} /></label>
                            <PaletteColorPicker
                              color={productsListCell.modal_background_color}
                              disableAlpha={true}
                              palette={project.color_palette}
                              onChange={(color) => {
                                const rgb = color.rgb;
                                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                                mutationFct({ variables: { input: { id: cell.id, modal_background_color: colorStr } }})
                              }}
                            />
                          </div>

                          <div className={'tsk-input-line'}>
                            <label><FormattedMessage id={`cell.products.modal.text.color`} /></label>
                            <PaletteColorPicker
                              color={productsListCell.modal_text_color}
                              disableAlpha={true}
                              palette={project.color_palette}
                              onChange={(color) => {
                                const rgb = color.rgb;
                                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                                mutationFct({ variables: { input: { id: cell.id, modal_text_color: colorStr } }})
                              }}
                            />
                          </div>
                        </NeedAdmin>

                        <div className={'tsk-input-block'}>
                          <label><FormattedMessage id={'cell.products.sort.order.label'} /></label>
                          <ProductsSortOrder
                            products={productsListCell.products}
                            onOrderChanged={(products) => {
                              mutationFct({variables: {input: {id: cell.id, products_order: products.map(p => p.id)}}});
                            }}
                          />
                        </div>

                        <NeedAdmin>
                          <div className={'tsk-input-line'}>
                            <label htmlFor={'font'}><FormattedMessage id={'cell.text.font.label'} /></label>
                            <select
                              value={knownFont ? productsListCell.font : ''}
                              onChange={(e) => {
                                if (e.target.value !== '') {
                                  mutationFct({variables: {input: {id: cell.id, font: e.target.value}}});
                                }
                              }}
                            >
                              <option value={''}/>
                              {project.fonts.map((font) =>
                                <option key={font.id} value={font.name}>{font.name}</option>
                              )}
                            </select>
                          </div>
                          <div className={'tsk-input-block'}>
                            <label><FormattedMessage id={`cell.products.categories_filter.label`} /></label>
                            <div className={'tsk-category-list'}>
                              {project.categories.map((cat) =>
                                <label key={cat.id}>
                                  <input type={"checkbox"}
                                         checked={!!productsListCell.categories_filter.find(c => c.id === cat.id)}
                                         onChange={(e) => {
                                           const newFilter = e.target.checked ?
                                             [...productsListCell.categories_filter, cat] :
                                             productsListCell.categories_filter.filter(c => c.id !== cat.id);
                                           mutationFct({variables: {input: {id: cell.id, categories_filter: newFilter.map(c => c.id)}}});
                                         }}
                                  />
                                  {cat.name}
                                </label>
                              )}
                            </div>
                          </div>
                        </NeedAdmin>
                      </Fragment>
                    )}
                  </Mutation>
                )

              }}
            </Query>
          )}}
      </Query>
    );
  }
}

export default withRouter(ProductsListCellEditor);
