import React from "react";
import { Mutation, Query } from "react-apollo";
import queries from "../../../queries";
import ImageUploader from "../../uploaders/ImageUploader";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import fieldCreationDialog from "../../dialogs/FieldCreationDialog";
import FieldEditor from "./FieldEditor";

class WheelCellEditor extends React.Component {
  state = {
    background_color: '#000000',
    text_color: '#000000',
    border_color: '#000000',
    offset: 0,
    slots: 16,
    isChange: false,
  };

  handleSlotsChange = (e) => {
    this.setState({isChange: true, slots: e.target.value });
  };
  
  handleOffsetChange = (e) => {
    this.setState({isChange: true, offset: e.target.value });
  };

  handleDefaultSlotChange = (e) => {
    this.setState({isChange: true});
  }
  
  render() {
    const { cell, language, screenType } = this.props;

    if (!cell) return null;

    return (
      <Query query={queries.cells.wheel.get} variables={{ id: cell.id, screen_type_id: screenType.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { wheelCell } = data;

          if (!wheelCell) return null;

          if ((wheelCell.offset !== this.state.offset || wheelCell.slots !== this.state.slots) && !this.state.isChange) {
            this.setState({
              slots: wheelCell.slots,
              offset: wheelCell.offset,
              cell_id: wheelCell.id,
              background_color: wheelCell.background_form_color,
              text_color: wheelCell.text_form_color,
              border_color: wheelCell.border_form_color,
            });
          }
          
          const { imageUriValue, mutation, mutationInputFromUri } = !!language
            ? {
                imageUriValue: (_.filter(wheelCell.i18n, { language_code: language.code })[0] || {}).wheel_uri,
                mutation: queries.cells.i18n.update,
                mutationInputFromUri: (wheelUri) => ({
                  cell_id: cell.id,
                  language_code: language.code,
                  wheel_uri: wheelUri,
                }),
              }
            : {
                imageUriValue: wheelCell.wheel_uri,
                mutation: queries.cells.wheel.update,
                mutationInputFromUri: (wheelUri) => ({
                  id: cell.id,
                  wheel_uri: wheelUri,
                }),
              };

          return (
            <Mutation
              mutation={mutation}
              refetchQueries={() => [{ query: queries.cells.wheel.get, variables: { id: cell.id } }]}
              onCompleted={this.handleMutationCompleted}
              onError={this.handleMutationError}
            >
              {(mutationFct) => (
                <>
                  <ImageUploader
                    value={imageUriValue}
                    onUpload={(wheel) => {
                      const data = mutationInputFromUri(wheel);
                      mutationFct({ variables: { input: data } });
                    }}
                  />
                  <br />
                  <label slot="slots">
                    <FormattedMessage id={"cell.wheel.slots"} />
                  </label>
                  <input
                    type="number"
                    name="slots"
                    onChange={this.handleSlotsChange}
                      value={this.state.slots}
                    min={0}
                     max={64}
                  >
                  </input>
                  <br />
                  <label slot="offset">
                    <FormattedMessage id={"cell.wheel.offset"} />
                  </label>
                      <input
                      name="offset"
                      type="number"
                      onChange={this.handleOffsetChange}
                      value={this.state.offset}
                      min={0}
                      max={360}
                  />
                  <br />
                      <br/>
                      <br/>
                      <label htmlFor="background">
                      <FormattedMessage id={"cell.wheel.color_background"} />
                      </label>
                      <input type="color" value={this.state.background_color} onChange={(e)=>{this.setState({background_color: e.target.value})}} name="background"></input>
                      <br/>
                      <label htmlFor="background">
                        <FormattedMessage id={"cell.wheel.color_text"} />
                      </label>
                      <input type="color" value={this.state.text_color} onChange={(e)=>{this.setState({text_color: e.target.value})}} name="background"></input>
                      <br/>
                      <label htmlFor="background">
                        <FormattedMessage id={"cell.wheel.color_border"} />
                      </label>
                      <input type="color" value={this.state.border_color} onChange={(e)=>{this.setState({border_color: e.target.value})}} name="background"></input>
                      <br/>
                  <Mutation
                            mutation={queries.form_fields.create}
                            refetchQueries={() => [{ query: queries.cells.wheel.get, variables: {id: wheelCell.id} }]}
                          >
                          {(createField) => (
                            <>
                            <br/>
                            <br/>
                              {
                                wheelCell.fields.map((field)=>{
                                  return(
                                    <div key={field.id} className={'tsk-input-line'}>
                                      <FieldEditor field={field} cell={cell} screenType={screenType} language={language}/>
                                    </div>
                                    )
                                })
                              }
                              <br/>
                              <button className={'tsk-button'} onClick={async () => {
                              const newField = await fieldCreationDialog({cell_id: cell.id});
                              await createField({variables: {input: newField}});
                            }}>
                              <FormattedMessage id={'common.button.new'}/>
                              </button>
                              </>
                          )}
                  </Mutation>
                  <br/>
                  <br/>
                  <button
                            className={'tsk-button'}
                            onClick={() => {

                            const data = {
                              id: cell.id,
                              offset: parseInt(this.state.offset),
                              slots: parseInt(this.state.slots),
                              background_form_color: this.state.background_color,
                              text_form_color: this.state.text_color,
                              border_form_color: this.state.border_color,
                            };
                            mutationFct({variables: {input: data}})
                          }}>
                            <FormattedMessage id={'common.button.submit'}/>
                            </button>
                </>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default WheelCellEditor;
