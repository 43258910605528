import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import {FormattedMessage, injectIntl} from "react-intl";
import fieldCreationDialog from "../../dialogs/FieldCreationDialog";
import PaletteColorPicker from "../../PaletteColorPicker";
import {withRouter} from "react-router-dom";
import {stateOrValue} from "../../../utils";
import _ from "lodash";
import FieldEditor from "./FieldEditor";

class FormCellEditor extends React.Component  {

  state = {
    i18n: {}
  };

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  colorPicker = (formCell, fieldName, palette, mutationFct) => (
    <div className={'tsk-input-line'}>
      <label><FormattedMessage id={`cell.email_form.${fieldName}.label`} /></label>
      <PaletteColorPicker
        color={formCell[fieldName]}
        disableAlpha={true}
        palette={palette}
        onChange={(color) => {
          const rgb = color.rgb;
          const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
          mutationFct({ variables: { input: { id: formCell.id, [fieldName]: colorStr } }})
        }}
      />
    </div>
  );

  render() {
    const { projectId, pageId } = this.props.match.params;
    const { cell, language, screenType, intl } = this.props;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {(projectQueryResponse) => {
          if (projectQueryResponse.loading) return null;
          if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
          const {project} = projectQueryResponse.data;
          if(!project) return null;

          return (
            <Query query={queries.cells.form.get} variables={{ id: cell.id, screen_type_id: screenType.id }}>
              {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return `Error!: ${error}`;

                const { formCell } = data;
                if (!formCell) return null;

                const linkToNoneTxt = intl.formatMessage({id: 'cell.links.selector.none'});
                const linkToBackTxt = intl.formatMessage({id: 'cell.links.selector.back'});

                if(!!language) {
                  const i18nState = this.state.i18n[language.code] || {};
                  const i18nValues = _.filter(formCell.i18n, {language_code: language.code})[0] || {};
                  return (
                    <Mutation
                      mutation={queries.cells.i18n.update}
                      refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
                    >
                      {(i18nCell) => (
                        <Fragment>
                          <div className={'tsk-settings-block'}>
                            <div className={'tsk-input-line'}>
                              <label htmlFor={'submit_button_text'}><FormattedMessage id={'cell.email_form.submit_button_text.label'} /></label>
                              <input name={'submit_button_text'} value={stateOrValue(i18nState.submit_button_text, i18nValues.submit_button_text, {defaultValue: ''})} onChange={(e) => {
                                this.setI18nState(language, i18nState, {submit_button_text: e.target.value})
                              }}/>
                            </div>

                            <div className={'tsk-input-line'}>
                              <label htmlFor={'confirmation_text'}><FormattedMessage id={'cell.email_form.confirmation_text.label'} /></label>
                              <input name={'confirmation_text'} value={stateOrValue(i18nState.confirmation_text, i18nValues.confirmation_text, {defaultValue: ''})} onChange={(e) => {
                                this.setI18nState(language, i18nState, {confirmation_text: e.target.value})
                              }}/>
                            </div>

                            <div className={'tsk-input-line'}>
                              <label htmlFor={'error_text'}><FormattedMessage id={'cell.email_form.error_text.label'} /></label>
                              <input name={'error_text'} value={stateOrValue(i18nState.error_text, i18nValues.error_text, {defaultValue: ''})} onChange={(e) => {
                                this.setI18nState(language, i18nState, {error_text: e.target.value})
                              }}/>
                            </div>
                          </div>

                          {stateOrValue(this.state.send_email, formCell.send_email) &&
                          <>
                            <div className={'tsk-input-line'}>
                              <label htmlFor={'email_subject'}><FormattedMessage id={'cell.email_form.email_subject.label'} /></label>
                              <input name={'email_subject'} value={stateOrValue(i18nState.email_subject, i18nValues.email_subject, {defaultValue: ''})} onChange={(e) => {
                                this.setI18nState(language, i18nState, {email_subject: e.target.value})
                              }}/>
                            </div>

                            <div className={'tsk-input-line'}>
                              <label htmlFor={'email_body'}><FormattedMessage id={'cell.email_form.email_body.label'} /></label>
                              <textarea name={'email_body'} value={stateOrValue(i18nState.email_body, i18nValues.email_body, {defaultValue: ''})} onChange={(e) => {
                                this.setI18nState(language, i18nState, {email_body: e.target.value})
                              }}/>
                            </div>
                          </>
                          }

                          <div className={'tsk-settings-block'}>
                            <label><FormattedMessage id={'cell.form.fields.label'} /></label>
                            {formCell.fields.map((field) => (
                              <div key={field.id} className={'tsk-input-line'}>
                                <FieldEditor field={field} language={language} cell={cell} screenType={screenType} />
                              </div>
                            ))}
                          </div>

                          <div className={'tsk-bottom-buttons'}>
                            <button className={'tsk-button'} onClick={() => {
                              const data = {
                                cell_id: cell.id,
                                language_code: language.code,
                                ...(i18nState.submit_button_text !== undefined ? {submit_button_text: i18nState.submit_button_text} : {}),
                                ...(i18nState.confirmation_text !== undefined ? {confirmation_text: i18nState.confirmation_text} : {}),
                                ...(i18nState.error_text !== undefined ? {error_text: i18nState.error_text} : {}),
                                ...(i18nState.email_subject !== undefined ? {email_subject: i18nState.email_subject} : {}),
                                ...(i18nState.email_body !== undefined ? {email_body: i18nState.email_body} : {}),
                              };
                              i18nCell({ variables: { input: data }})
                            }}>
                              <FormattedMessage id={'common.button.submit'} />
                            </button>
                          </div>
                        </Fragment>
                      )}
                    </Mutation>
                  )
                }

                return (
                  <Mutation
                    mutation={queries.cells.form.update}
                    refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
                  >
                    {(mutationFct) => (
                      <Fragment>
                        <div className={'tsk-settings-block'}>
                          {this.colorPicker(formCell, "border_color", project.color_palette, mutationFct)}
                          {this.colorPicker(formCell, "submit_text_color", project.color_palette, mutationFct)}
                          {this.colorPicker(formCell, "input_text_color", project.color_palette, mutationFct)}
                          {this.colorPicker(formCell, "confirmation_text_color", project.color_palette, mutationFct)}
                          {this.colorPicker(formCell, "error_text_color", project.color_palette, mutationFct)}
                          {this.colorPicker(formCell, "input_background_color", project.color_palette, mutationFct)}
                          {this.colorPicker(formCell, "submit_background_color", project.color_palette, mutationFct)}


                          <label htmlFor={'font'}><FormattedMessage id={'cell.text.font.label'} /></label>
                          <select
                            value={stateOrValue(this.state.font, formCell.font, {defaultValue: project.fonts[0].name})}
                            onChange={(e) => {
                              this.setState({font: e.target.value});
                            }}
                          >
                            {project.fonts.map((font) =>
                              <option key={font.id} value={font.name}>{font.name}</option>
                            )}
                          </select>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'submit_button_text'}><FormattedMessage id={'cell.email_form.submit_button_text.label'} /></label>
                            <input name={'submit_button_text'} value={stateOrValue(this.state.submit_button_text, formCell.submit_button_text, {defaultValue: ''})} onChange={(e) => {
                              this.setState({submit_button_text: e.target.value})
                            }}/>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'confirmation_text'}><FormattedMessage id={'cell.email_form.confirmation_text.label'} /></label>
                            <input name={'confirmation_text'} value={stateOrValue(this.state.confirmation_text, formCell.confirmation_text, {defaultValue: ''})} onChange={(e) => {
                              this.setState({confirmation_text: e.target.value})
                            }}/>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'error_text'}><FormattedMessage id={'cell.email_form.error_text.label'} /></label>
                            <input name={'error_text'} value={stateOrValue(this.state.error_text, formCell.error_text, {defaultValue: ''})} onChange={(e) => {
                              this.setState({error_text: e.target.value})
                            }}/>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'send_email'}><FormattedMessage id={'cell.form.send_email.label'}/></label>
                            <input type={'checkbox'} checked={stateOrValue(this.state.send_email, formCell.send_email)} onChange={(e) => {
                              this.setState({send_email: e.target.checked});
                            }} />
                          </div>

                          {stateOrValue(this.state.send_email, formCell.send_email) &&
                          <>
                            <div className={'tsk-input-line'}>
                              <label htmlFor={'from_email'}><FormattedMessage id={'cell.email_form.from_email.label'} /></label>
                              <input name={'from_email'} value={stateOrValue(this.state.from_email, formCell.from_email, {defaultValue: ''})} onChange={(e) => {
                                this.setState({from_email: e.target.value})
                              }}/>
                            </div>

                            <div className={'tsk-input-line'}>
                              <label htmlFor={'email_subject'}><FormattedMessage id={'cell.email_form.email_subject.label'} /></label>
                              <input name={'email_subject'} value={stateOrValue(this.state.email_subject, formCell.email_subject, {defaultValue: ''})} onChange={(e) => {
                                this.setState({email_subject: e.target.value})
                              }}/>
                            </div>

                            <div className={'tsk-input-line'}>
                              <label htmlFor={'email_body'}><FormattedMessage id={'cell.email_form.email_body.label'} /></label>
                              <textarea name={'email_body'} value={stateOrValue(this.state.email_body, formCell.email_body, {defaultValue: ''})} onChange={(e) => {
                                this.setState({email_body: e.target.value})
                              }}/>
                            </div>
                          </>
                          }

                          <div className={'tsk-input-line'}>
                            <label><FormattedMessage id={'cell.form.redirect.label'} /></label>
                            <select name={'form-redirect-to'} value={ stateOrValue(this.state.redirect_page_id, formCell.redirect_page_id, {defaultValue: ''})} onChange={(e) => {
                              const newLink = e.target.value === '' ? null : e.target.value;
                              this.setState({redirect_page_id: newLink});
                            }}>
                              <option value={''}>{linkToNoneTxt}</option>
                              {project.pages.map((page) => {
                                if(page.id === pageId) return null;
                                return <option key={page.id} value={page.id}>{page.name}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className={'tsk-settings-block'}>
                          <label><FormattedMessage id={'cell.form.fields.label'} /></label>
                          {formCell.fields.map((field) => (
                            <div key={field.id} className={'tsk-input-line'}>
                              <FieldEditor field={field} cell={cell} screenType={screenType}/>
                            </div>
                          ))}
                          <Mutation
                            mutation={queries.form_fields.create}
                            refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
                          >
                            {(createField) => (
                              <button className={'tsk-button'} onClick={async () => {
                                const newField = await fieldCreationDialog({cell_id: cell.id});
                                await createField({variables: {input: newField}});
                              }}>
                                <FormattedMessage id={'common.button.new'}/>
                              </button>
                            )}
                          </Mutation>
                        </div>

                        <div className={'tsk-bottom-buttons'}>
                          <button className={'tsk-button'} onClick={() => {
                            const data = {
                              id: cell.id,
                              ...(this.state.font !== undefined ? {font: this.state.font} : {}),
                              ...(this.state.submit_button_text !== undefined ? {submit_button_text: this.state.submit_button_text} : {}),
                              ...(this.state.confirmation_text !== undefined ? {confirmation_text: this.state.confirmation_text} : {}),
                              ...(this.state.error_text !== undefined ? {error_text: this.state.error_text} : {}),
                              ...(this.state.send_email !== undefined ? {send_email: this.state.send_email} : {}),
                              ...(this.state.from_email !== undefined ? {from_email: this.state.from_email} : {}),
                              ...(this.state.email_subject !== undefined ? {email_subject: this.state.email_subject} : {}),
                              ...(this.state.email_body !== undefined ? {email_body: this.state.email_body} : {}),
                              ...(this.state.redirect_page_id !== undefined ? {redirect_page_id: this.state.redirect_page_id} : {}),
                            };
                            mutationFct({ variables: { input: data }})
                          }}>
                            <FormattedMessage id={'common.button.submit'} />
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </Mutation>
                )

              }}
            </Query>
          )}}
      </Query>
    );
  }
}

export default withRouter(injectIntl(FormCellEditor));
