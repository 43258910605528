import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {TabMenuCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";
import {Query} from "react-apollo";

class TabMenuCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={'ellipsis-h'} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.tabs.get}
        renderingCondition={(tabMenuCell) => !!tabMenuCell.tab_menu_id}
      >
        {(tabMenuCell) => (
          <Query query={queries.tabs.get} variables={{id: tabMenuCell.tab_menu_id}}>
            {({loading, error, data}) => {
              if (loading) return 'Loading...';
              if (error) return `Error! ${error.message}`;

              const {tabMenu} = data;
              return (
                <TabMenuCell preview={true}
                             cell={{...tabMenuCell, tabMenu}}
                             linkFromPageId={(pageId, children) => children}
                />
              )
            }}
          </Query>
        )}
      </SpecificCellPreview>
    );
  }

}

export default TabMenuCellPreview;
