import React from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {stateOrValue} from "../../../utils";

class IframeCellEditor extends React.Component  {

  state = {
    i18n: {}
  };

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  render() {
    const { cell, language } = this.props;

    return (
      <Query query={queries.cells.iframe.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { iframeCell } = data;
          if (!iframeCell) return null;

          if(!!language) {
            const i18nState = this.state.i18n[language.code] || {};
            const i18nValues = _.filter(iframeCell.i18n, {language_code: language.code})[0] || {};
            return (
              <Mutation
                mutation={queries.cells.i18n.update}
                refetchQueries={() => [{ query: queries.cells.iframe.get, variables: {id: cell.id} }]}
              >
                {(i18nCell) => (
                  <div>
                    <div className={'tsk-input-line'}>
                      <label htmlFor={'uri'}><FormattedMessage id={'cell.iframe.uri.label'} /></label>
                      <input name={'uri'} value={stateOrValue(i18nState.uri, i18nValues.uri, {defaultValue: ''})} onChange={(e) => {
                        this.setI18nState(language, i18nState, {uri: e.target.value})
                      }}/>
                    </div>

                    <div className={'tsk-bottom-buttons'}>
                      <button
                        className={'tsk-button'}
                        onClick={() => {

                        const data = {
                          cell_id: cell.id,
                          language_code: language.code,
                          ...(i18nState.uri !== undefined ? {uri: i18nState.uri} : {}),
                        };
                        i18nCell({ variables: { input: data }})
                      }}>
                        <FormattedMessage id={'common.button.submit'} />
                      </button>
                    </div>
                  </div>
                )}
              </Mutation>
            )
          }
          return (
            <Mutation
              mutation={queries.cells.iframe.update}
              refetchQueries={() => [{ query: queries.cells.iframe.get, variables: {id: cell.id} }]}
            >
              {(updateIframeCell) => (
                <div>
                  <div className={'tsk-input-line'}>
                    <label htmlFor={'uri'}><FormattedMessage id={'cell.iframe.uri.label'} /></label>
                    <input name={'uri'} value={stateOrValue(this.state.uri, iframeCell.uri)} onChange={(e) => {
                      this.setState({uri: e.target.value});
                    }}/>
                  </div>
                  <div className={'tsk-bottom-buttons'}>
                    <button
                      className={'tsk-button'}
                      onClick={() => {

                        const data = {
                          id: cell.id,
                          uri: this.state.uri,
                        };
                        updateIframeCell({ variables: { input: data }})
                      }}>
                      <FormattedMessage id={'common.button.submit'} />
                    </button>
                  </div>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    );
  }
}

export default IframeCellEditor;
