import React from 'react';
import PropTypes from 'prop-types';
import RichTextEditor, {ButtonGroup, Dropdown} from "react-rte";
import PaletteColorPicker from "../../PaletteColorPicker";
import createStyles from "draft-js-custom-styles";

class RichTextContentEditor extends React.Component {

  render() {
    const {spacingStyle, value, onChange, project} = this.props;

    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ]
    };

    const { styles, customStyleFn } = createStyles(['font-size', 'color', 'font-family'], 'TSK_CUSTOM');

    const fontChoices = new Map(project.fonts.map((font) => {
      return [font.name, {label: font.name}]
    }));

    return (
      <div style={spacingStyle} className={'tsk-richtexteditor'}>
        <RichTextEditor
          customStyleFn={customStyleFn}
          editorClassName={'tsk-textcell-rte-content'}
          value={value}
          onChange={onChange}
          toolbarConfig={toolbarConfig}
          customControls={[
            (setValue, getValue, editorState) => {
              let sizeChoices = new Map([
                ['0.5em', {label: 'smallest'}],
                ['0.75em', {label: 'small'}],
                ['1em', {label: 'medium'}],
                ['1.5em', {label: 'large'}],
                ['2em', {label: 'largest'}],
              ]);
              const currentFontSize = styles.fontSize.current(editorState) || '1em';
              return (
                <ButtonGroup key={'font-size-buttons'}>
                  <Dropdown
                    choices={sizeChoices}
                    selectedKey={currentFontSize}
                    onChange={(newValue) => {
                      const newState = styles.fontSize.toggle(editorState, newValue);
                      const newEditorValue = value.setEditorState(newState);
                      onChange(newEditorValue);
                    }}
                  />
                </ButtonGroup>
              );
            },
            (setValue, getValue, editorState) => {
              const currentFontFamily = styles.fontFamily.current(editorState) || project.fonts[0].name;

              return (
                <ButtonGroup key={'font-family-buttons'}>
                  <Dropdown
                    choices={fontChoices}
                    selectedKey={currentFontFamily}
                    onChange={(newValue) => {
                      const newState = styles.fontFamily.toggle(editorState, newValue);
                      const newEditorValue = value.setEditorState(newState);
                      onChange(newEditorValue);
                    }}
                  />
                </ButtonGroup>
              );
            },
            (setValue, getValue, editorState) => {
              const currentColor = styles.color.current(editorState);
              return (
                <ButtonGroup key={'color-buttons'}>
                  <PaletteColorPicker
                    color={currentColor}
                    disableAlpha={true}
                    palette={project.color_palette}
                    onChange={(color) => {
                      const rgb = color.rgb;
                      const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                      console.log('Setting color to: ', colorStr);
                      const newState = styles.color.toggle(editorState, colorStr);
                      const newEditorValue = value.setEditorState(newState);
                      onChange(newEditorValue);
                    }}
                  />
                </ButtonGroup>
              );
            }
          ]}
        />
      </div>
    )
  }
}

RichTextContentEditor.propType = {
  spacingStyle: PropTypes.object.required,
  value: PropTypes.object.required,
  onChange: PropTypes.func.required,
  project: PropTypes.object.required,
};

export default RichTextContentEditor;
