import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { identityProvider } from './utils';

export const backEndUri = process.env.REACT_APP_TREEOSK_BACKEND_URI ? process.env.REACT_APP_TREEOSK_BACKEND_URI : 'http://localhost:4000';
export const latestPublicationsLink = process.env.REACT_APP_LATEST_PUBLICATIONS_LINK;

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    setContext(async (_, { headers }) => {
      const token = await identityProvider.currentUser().jwt();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
    }),
    new HttpLink({
      uri: `${backEndUri}/graphql`,
      credentials: 'include'
    })
  ]),
  cache: new InMemoryCache()
});
identityProvider.on("logout", () => apolloClient.resetStore());

export const usersListMockData = process.env.NODE_ENV === 'development' ? [
      {
        "id": "2c1dce3a-dd76-400c-801a-65bd5a926bd7",
        "aud": "",
        "role": "",
        "email": "renaud+treeosk_user@26lights.com",
        "confirmed_at": "2019-05-27T14:16:30Z",
        "app_metadata": {
          "provider": "email",
          "roles": [
            "390df690-4e27-40f9-8a4a-40b570f90eca",
            "62cd61e7-79e5-4e5d-9384-f20e93e0de1f"
          ]
        },
        "user_metadata": {},
        "created_at": "2019-05-27T14:15:27Z",
        "updated_at": "2019-05-27T14:15:27Z"
      },
      {
        "id": "07d60d36-55fa-4b12-9cdc-41b77c4d8b2e",
        "aud": "",
        "role": "",
        "email": "jon@slantedmedia.be",
        "confirmed_at": "2019-05-16T08:08:43Z",
        "invited_at": "2019-04-29T10:24:33Z",
        "app_metadata": {
          "provider": "email",
          "roles": [
            "admin"
          ]
        },
        "user_metadata": {
          "full_name": "Jon"
        },
        "created_at": "2019-04-29T10:24:33Z",
        "updated_at": "2019-04-29T10:24:33Z"
      },
      {
        "id": "91c9da27-7285-4b37-bd43-e208f2a06abd",
        "aud": "",
        "role": "",
        "email": "renaud@26lights.com",
        "confirmed_at": "2019-04-29T10:23:56Z",
        "invited_at": "2019-04-29T09:26:40Z",
        "recovery_sent_at": "2019-04-29T09:59:35Z",
        "app_metadata": {
          "provider": "email",
          "roles": [
            "admin"
          ]
        },
        "user_metadata": {
          "full_name": "Renaud"
        },
        "created_at": "2019-04-29T09:26:40Z",
        "updated_at": "2019-04-29T09:26:40Z"
      }
    ] : undefined;
