import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import NeedAdmin from "../NeedAdmin";
import {latestPublicationsLink} from "../../config";

class Sidebar extends Component {

  state = { loggedIn: false };

  handleLogInClick() {
    const {identityProvider} = this.props;
    identityProvider.open();
  }

  handleLogOutClick() {
    const {identityProvider} = this.props;
    identityProvider.logout();
  }

  afterLogIn() {
    const {identityProvider} = this.props;
    identityProvider.close();
    this.setState({ loggedIn: true });
  }

  componentDidMount() {
    const {identityProvider} = this.props;
    identityProvider.on("login", () => this.afterLogIn());
    identityProvider.on("logout", () => this.setState({ loggedIn: false }));
  }


  logInOrOut() {
    const {identityProvider} = this.props;
    const user = identityProvider.currentUser();
    if (!user) {
      return (
        <li>
          <button className={'tsk-button tsk-link'} onClick={() => this.handleLogInClick()}>
            <FormattedMessage id='login.button' />
          </button>
        </li>
      )
    } else {
      return (
        <li>
          <button className={'tsk-button tsk-link'} onClick={() => this.handleLogOutClick()}>
            <FormattedMessage id='logout.button' />
          </button>
        </li>
      )
    }
  }

  render() {
    return (
      <div>
        <ul>
          <li>
            <Link to={'/project'}>
              <FormattedMessage id='projects.title'/>
            </Link>
            <Route path='/project/:projectId' render={({match}) =>
              <ul>
                <li>
                  <Link to={`/project/${match.params.projectId}`}>
                    <FormattedMessage id='project.pages.title'/>
                  </Link>
                </li>
                <NeedAdmin>
                  <li>
                    <Link to={`/project/${match.params.projectId}/tabMenu`}>
                      <FormattedMessage id='project.tabmenus.title'/>
                    </Link>
                  </li>
                </NeedAdmin>
                <li>
                  <Link to={`/project/${match.params.projectId}/products`}>
                    <FormattedMessage id='project.products.title'/>
                  </Link>
                </li>
                  <li>
                  <Link to={`/project/${match.params.projectId}/gameprizes`}>
                    <FormattedMessage id='project.products.gameprize'/>
                  </Link>
                  </li>
              </ul>
            } />
          </li>
          <NeedAdmin trueAdmin>
            <li>
              <Link to={'/admin/users'}>
                <FormattedMessage id='admin.users.title'/>
              </Link>
            </li>
            <li>
              <Link to={'/admin/displays'}>
                <FormattedMessage id="admin.displays.title"/>
              </Link>
            </li>
            {!!latestPublicationsLink &&
              <li>
                <a href={latestPublicationsLink} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage id={'publications.latest.link.label'} />
                </a>
              </li>
            }
          </NeedAdmin>
          {this.logInOrOut()}
        </ul>
      </div>
    );
  }
}

Sidebar.propTypes = {
  identityProvider: PropTypes.object.isRequired,
};

export default Sidebar;
