import React from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import { createConfirmation, confirmable } from 'react-confirm';
import {FormattedMessage} from "react-intl";
import DateRangePicker from '@renaudlenne/react-daterange-picker';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WithLang from "../WithLang";

import '@renaudlenne/react-daterange-picker/dist/css/react-calendar.css'

const stateDefinitions = {
  available: {
    color: null,
    label: 'Available',
  },
  selected: {
    selectable: false,
    color: '#ffd200',
    label: 'Selected',
  },
};

class PeriodsPickerDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    options: PropTypes.object,       // arguments of your confirm function
  };

  state={
    periods: [],
    period: null
  };

  onSelect = period => {
    const {multi = true} = this.props;
    if(multi) {
      this.setState({periods: [
          ...this.state.periods,
          {
            state: 'selected',
            range: period,
          }
        ].sort((a, b) => a.range.start.valueOf() - b.range.start.valueOf())});
    } else {
      this.setState({period});
    }
  };

  setStateFromProps() {
    if(!this.props.options) return;
    this.setState({
      periods: (this.props.options.periods || []).map(period => ({
        state: 'selected',
        range: period,
      })).sort((a, b) => a.range.start.valueOf() - b.range.start.valueOf())
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.options && !!this.props.options.periods && (
      !prevProps.options || !prevProps.options.periods || !_.isEqual(prevProps.options.periods, this.props.options.periods)
    )) {
      this.setStateFromProps();
    }
  }

  componentDidMount() {
    this.setStateFromProps();
  }

  render() {
    const {proceed, show, cancel, options: {additionalChildren,  multi = true, labelId},} = this.props;
    const {period, periods} = this.state;
    return (
      <WithLang>
        <div className={'tsk-dialog-container'} style={ show ? {} : {display : 'none'}}>
          <div className={'tsk-dialog'}>
            <div className={'tsk-dialog-content'}>
              {!!additionalChildren && additionalChildren}
              <label style={{display: "block"}} htmlFor={'txt-input'}><FormattedMessage id={labelId} /></label>
              <DateRangePicker
                minimumDate={new Date()}
                dateStates={periods}
                fullDayStates={true}
                stateDefinitions={stateDefinitions}
                numberOfCalendars={2}
                value={period}
                singleDateRange={true}
                defaultState="available"
                onSelect={this.onSelect}
              />
            </div>
            <ul>
              {periods.map((p, idx) => (
                <li key={idx}>
                  <span>{p.range.start.format('DD/MM/YYYY')} - {p.range.end.format('DD/MM/YYYY')}</span>
                  &nbsp;
                  <span
                    className={"tsk-link-icon"}
                    onClick={() => this.setState({periods: [...periods.slice(0, idx), ...periods.slice(idx + 1)]})}
                  >
                    <FontAwesomeIcon icon={'trash-alt'} />
                  </span>
                </li>
              ))}
            </ul>
            <div className={'tsk-dialog-buttons'}>
              <button className={'tsk-button'} onClick={() => cancel()}>
                <FormattedMessage id={'dialog.cancel.button'} />
              </button>
              <button className={'tsk-button'} style={{marginLeft: '1em'}}
                      onClick={() => proceed(multi ? periods.map(p => p.range) : period)}
                      disabled={!multi && !period}
              >
                <FormattedMessage id={'common.button.save'} />
              </button>
            </div>
          </div>
        </div>
      </WithLang>
    )
  }
}

// create confirm function
const periodsPickerDialog = createConfirmation(confirmable(PeriodsPickerDialog));

export default (options = {}) => {
  return periodsPickerDialog({ options });
}
