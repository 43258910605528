import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import ImageUploader from "../../uploaders/ImageUploader";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import BackgroundSelector from "../../BackgroundSelector";
import confirmDialog from "../../dialogs/ConfirmDialog";
import {apolloClient} from "../../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {stateOrValue} from "../../../utils";
import PaletteColorPicker from "../../PaletteColorPicker";

class CarouselCellEditor extends React.Component  {
  state = {};

  render() {
    const { cell, language, intl, screenType } = this.props;
    const { projectId, pageId } = this.props.match.params;

    const deleteImageConfirmationContent = (
      <FormattedMessage id={'cell.carousel.deletepicture.dialog.message'} values={{br: <br/>}} />
    );

    return (
      <Query query={queries.cells.carousel.get} variables={{ id: cell.id, screen_type_id: screenType.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { carouselCell } = data;
          if (!carouselCell) return null;

          return (
            <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
              {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return `Error!: ${error}`;

                const {project} = data;
                const linkToNoneTxt = intl.formatMessage({id: 'cell.links.selector.none'});

                const slides = carouselCell.slides.filter((slide) => {
                  if(!!language) {
                    return slide.language_code === language.code
                  }
                  return !slide.language_code;
                });

                return (
                  <Fragment>
                    <Mutation
                      mutation={queries.cells.carousel.updateSlide}
                      refetchQueries={() => [{ query: queries.cells.carousel.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
                    >
                      {(updateSlide) => (
                        <ol>
                          {slides.map((slide) => {
                            const selectValue = slide.to_page_id ? slide.to_page_id : '';

                            return (
                              <li key={slide.id}>
                                <div className={'tsk-input-line'}>
                                  <ImageUploader value={slide.image_uri} onUpload={(imageUri) => {
                                    updateSlide({ variables: { input: {id: slide.id, image_uri: imageUri} }});
                                  }} />
                                  <label htmlFor={'cell-links-to'}>
                                    <FormattedMessage id={'cell.links.selector.label'} />
                                  </label>
                                  <select name={'cell-links-to'} value={ selectValue } onChange={(e) => {
                                    const newLink = e.target.value === '' ? null : e.target.value;
                                    if(newLink !== slide.to_page_id) {
                                      updateSlide({ variables:{ input: {id: slide.id, to_page_id: newLink}}});
                                    }
                                  }}>
                                    <option value={''}>{linkToNoneTxt}</option>
                                    {project.pages.map((page) => {
                                      if(page.id === pageId) return null;
                                      return <option key={page.id} value={page.id}>{page.name}</option>;
                                    })}
                                  </select>
                                  <span
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      await confirmDialog(deleteImageConfirmationContent).then(
                                        async (result) => {
                                          await apolloClient.mutate({
                                            mutation: queries.cells.carousel.deleteSlide,
                                            variables: {input: slide.id},
                                            refetchQueries: () => [{ query: queries.cells.carousel.get, variables: {id: cell.id, screen_type_id: screenType.id} }]
                                          })
                                        }
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon icon={'trash-alt'} />
                                  </span>
                                </div>
                              </li>
                            )
                          })}
                        </ol>
                      )}
                    </Mutation>
                    <Mutation
                      mutation={queries.cells.carousel.addSlide}
                      refetchQueries={() => [{ query: queries.cells.carousel.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
                    >
                      {(addSlide) => (
                        <ImageUploader value={undefined} onUpload={(imageUri) => {
                          const mutationInput = {
                            cell_id: cell.id,
                            image_uri: imageUri,
                            ...(!!language ? {language_code: language.code} : {}),
                          };
                          addSlide({ variables: { input: mutationInput }});
                        }} />
                      )}
                    </Mutation>
                    {!language &&
                    <Mutation
                      mutation={queries.cells.carousel.update}
                      refetchQueries={() => [{query: queries.cells.carousel.get, variables: {id: cell.id, screen_type_id: screenType.id}}]}
                    >
                      {(updateCarousel) => (
                        <Fragment>
                          <div className={'tsk-input-line'}>
                            <label htmlFor={'slidePadding'}>
                              <FormattedMessage id={'cell.carousel.slidepadding.label'}/>
                            </label>
                            <input name={'slidePadding'}
                                   type={'range'}
                                   min={-300}
                                   max={200}
                                   step={1}
                                   value={stateOrValue(this.state.padding, carouselCell.padding)}
                                   onChange={(e) => this.setState({padding: parseInt(e.target.value)})}
                            />
                            <span>{stateOrValue(this.state.padding, carouselCell.padding)}</span>
                            <button className={'tsk-button tsk-small tsk-upper'} onClick={() => {
                              this.setState({padding: 0});
                            }}>
                              <FormattedMessage id={'cell.text.reset.label'}/>
                            </button>
                          </div>

                          <BackgroundSelector
                            value={carouselCell}
                            palette={project.color_palette}
                            onChange={(bgValues) => this.setState(bgValues)}
                          />
                          <div className={'tsk-setting-section'}>
                            <div className={'tsk-input-line'}>
                              <label htmlFor={'arrows'}>
                                <FormattedMessage id={'cell.carousel.arrows.label'}/>
                              </label>
                              <input type={'checkbox'} checked={stateOrValue(this.state.arrows, carouselCell.arrows)} onChange={(e) => {
                                this.setState({arrows: e.target.checked});
                              }} />
                            </div>
                            {stateOrValue(this.state.arrows, carouselCell.arrows) && (
                              <Fragment>
                                <div className={'tsk-input-line'}>
                                  <label htmlFor={'arrows'}>
                                    <FormattedMessage id={'cell.carousel.arrows.innercolor.label'}/>
                                  </label>
                                  <PaletteColorPicker
                                    color={stateOrValue(this.state.arrowsInnerColor, carouselCell.arrows_inner_color, {defaultValue: '#000000'})}
                                    disableAlpha={true}
                                    palette={project.color_palette}
                                    onChange={(color) => {
                                      const rgb = color.rgb;
                                      this.setState({arrowsInnerColor: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
                                    }}
                                  />
                                </div>
                                <div className={'tsk-input-line'}>
                                  <label htmlFor={'arrows'}>
                                    <FormattedMessage id={'cell.carousel.arrows.outercolor.label'}/>
                                  </label>
                                  <PaletteColorPicker
                                    color={stateOrValue(this.state.arrowsOuterColor, carouselCell.arrows_outer_color, {defaultValue: '#FFFFFF'})}
                                    disableAlpha={true}
                                    palette={project.color_palette}
                                    onChange={(color) => {
                                      const rgb = color.rgb;
                                      this.setState({arrowsOuterColor: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
                                    }}
                                  />
                                </div>

                              </Fragment>
                            )}
                          </div>


                          <div className={'tsk-bottom-buttons'}>
                            <button
                              className={'tsk-button'}
                              onClick={() => {
                                const mutationInput = {
                                  id: cell.id,
                                  ...(this.state.backgroundType !== undefined ? {background_type: this.state.backgroundType} : {}),
                                  ...(this.state.backgroundImageUri !== undefined ? {background_image_uri: this.state.backgroundImageUri} : {}),
                                  ...(this.state.backgroundVideoUri !== undefined ? {background_video_uri: this.state.backgroundVideoUri} : {}),
                                  ...(this.state.backgroundColor !== undefined ? {background_color: this.state.backgroundColor} : {}),
                                  ...(this.state.padding !== undefined ? {padding: this.state.padding} : {}),
                                  ...(this.state.arrows !== undefined ? {arrows: this.state.arrows} : {}),
                                  ...(this.state.arrowsInnerColor !== undefined ? {arrows_inner_color: this.state.arrowsInnerColor} : {}),
                                  ...(this.state.arrowsOuterColor !== undefined ? {arrows_outer_color: this.state.arrowsOuterColor} : {}),
                                };
                                updateCarousel({variables: {input: mutationInput}});
                              }}>
                              <FormattedMessage id={'common.button.submit'} />
                            </button>
                          </div>
                        </Fragment>
                      )}
                    </Mutation>
                    }
                  </Fragment>
                )
              }}
            </Query>
          )
        }}
      </Query>
    );
  }
}

export default injectIntl(withRouter(CarouselCellEditor));
