import React, { useState } from "react"
import { Mutation } from "react-apollo"
import queries from "../../queries"
import { FormattedMessage } from "react-intl";
import moment from 'moment';

export default function PeriodeEditor({periode}){
    
    const [defines, setDifine] = useState(periode.periode_start && periode.periode_end);
    const [start, setStart] = useState((periode.periode_start) ? new Date(periode.periode_start) : new Date());
    const [end, setEnd] = useState((periode.periode_end) ? new Date(periode.periode_end) : new Date());
    const [nb, setNb] = useState(periode.qts_for_periode);

    console.log(start.toLocaleString());
    
    return(
        <Mutation mutation={queries.games.updatePeriode} refetchQueries={queries.games.getPrizePeriode}>
        {
            (mutationUpdate)=>{
                return(
                    <Mutation mutation={queries.games.deletePeriode} refetchQueries={queries.games.getPrizePeriode}>
                    {
                        (mutationDelete)=>{
                            return(
                                <div className={(defines) ? "tsk-periode-editor tsk-br-green" : "tsk-periode-editor tsk-br-red"}>
                                <label className="tsk-label"><FormattedMessage id="prize.preriode.start"/></label>
                                <input type="datetime-local" className="tsk-input" value={moment(start).format('YYYY-MM-DDTHH:mm:ss')} onChange={(e)=>{setStart(e.target.value)}}/>
                                <br/>
                                <label className="tsk-label"><FormattedMessage id="prize.preriode.end"/></label>
                                <input type="datetime-local" className="tsk-input" value={moment(end).format('YYYY-MM-DDTHH:mm:ss')} onChange={(e)=>{setEnd(e.target.value)}}/>
                                <br/>
                                <label className="tsk-label"><FormattedMessage id="prize.preriode.qts"/></label>
                                <input type="number" value={nb} onChange={(e)=>{setNb(e.target.value)}} min={0} className="tsk-input"/>
                                <br/>
                                <button className="tsk-button" onClick={() => {
                                    if (start && end && nb) {
                                        mutationUpdate({
                                            variables: {
                                                input: {
                                                    id: periode.id,
                                                    periode_start: new Date(start),
                                                    periode_end: new Date(end),
                                                    qts_for_periode: parseInt(nb)
                                                }
                                            }
                                        });
                                        setDifine(true);
                                    } else {
                                        console.log('Remplir les champs');
                                    }
                                }}>
                                <FormattedMessage id="display.mananagement.update"/>
                                </button>
                                
                                <button className="tsk-button-delete" onClick={()=>{mutationDelete({variables: {id: periode.id}})}}>
                                <FormattedMessage id="display.mananagement.delete"/>
                                </button>
                                </div>
                                )
                            }
                        }
                        </Mutation>
                        )
                    }
                }
                </Mutation>
                )
            }