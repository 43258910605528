import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import '../../style/variables.scss';
import '../../style/DisplaysList.scss';
import { Query } from 'react-apollo';
import queries from '../../queries';
import { FormattedMessage } from 'react-intl';
import NeedAdmin from '../NeedAdmin';

class DisplaysList extends Component{

    constructor(props) {
        super(props);
        this.state = {
          sortColumn: 'name',
          sortOrder: 'asc', // 'asc' for ascending, 'desc' for descending
          search: "",
          group_id: null,
        };
      }

      handleSort = (column) => {
        const { sortColumn, sortOrder } = this.state;
      
        if (sortColumn === column) {
          // Si vous cliquez sur la même colonne, basculez l'ordre de tri
          this.setState({
            sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
          });
        } else {
          // Si vous cliquez sur une nouvelle colonne, triez par ordre alphabétique ascendant par défaut
          this.setState({
            sortColumn: column,
            sortOrder: 'asc',
          });
        }
      };    

        render(){

        const { sortColumn, sortOrder } = this.state;
        const { groupId } = this.props.match.params;

        if(groupId !== 'all'){
        return (
            <NeedAdmin trueAdmin>
            <ul className='tsk-dispays-list-container'>
            <button className='tsk-btn-update' style={{width: '100px', margin: '20px'}} onClick={()=>{window.location.href = '/admin/displays'}}>Retour</button>
            <input className='tsk-input-search' type='text' placeholder='Search | Filter' value={this.state.search} onChange={(e)=>{this.setState({search: e.target.value})}}/>
            <li className='tsk-displays-row'>
            <div className={"tsk-displays-list-item"}>
            <div className='tsk-display-col-item bold'>Row</div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('name')}}><FormattedMessage id="displays.list.colone.name"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('location')}}><FormattedMessage id="displays.list.colone.location"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('project')}}><FormattedMessage id="displays.list.colone.current"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('connected')}}><FormattedMessage id="displays.list.colone.state"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('physical_id')}}><FormattedMessage id="displays.list.colone.physical_id"></FormattedMessage></div>
            <div className='tsk-display-col-item bold'><FormattedMessage id="displays.list.colone.version"></FormattedMessage></div>
            <div className='tsk-display-col-item bold'>Device group</div>
            </div>
            </li>
            <Query query={queries.displays.listByGroup} variables={{id: groupId}}>
            {({loading, error, data})=>{
                if(loading){
                    return 'loading';
                }if(error){
                    return error.message
                }else{
                  console.log(data);
                  const sortedData = data.devicesGroup.devices.slice().sort((a, b) => {
                    const aValue = a[this.state.sortColumn];
                    const bValue = b[this.state.sortColumn];
                
                    if(this.state.sortColumn === 'project'){
                      if (aValue !== null && bValue !== null) {
                        const aProjectName = a.project && a.project.name ? a.project.name : '';
                        const bProjectName = b.project && b.project.name ? b.project.name : '';
                
                        if (sortOrder === 'asc') {
                            return aProjectName.localeCompare(bProjectName);
                        } else {
                            return bProjectName.localeCompare(aProjectName);
                        }
                    } else {
                        return 0;
                      }
                    }
                    if (this.state.sortColumn === 'name' || this.state.sortColumn === 'physical_id') {
                      const aProjectName = aValue || 'no name';
                      const bProjectName = bValue || 'no name';
                  
                      if (sortOrder === 'asc') {
                        return aProjectName.localeCompare(bProjectName);
                      } else {
                        return bProjectName.localeCompare(aProjectName);
                      }
                    }

                    if(this.state.sortColumn === 'connected'){
                      const aProjectName = aValue ? 'yes' : 'no';
                      const bProjectName = bValue ? 'yes' : 'no';
                  
                      if (sortOrder === 'asc') {
                        return aProjectName.localeCompare(bProjectName);
                      } else {
                        return bProjectName.localeCompare(aProjectName);
                      }
                    }
                });
                
                return sortedData.map((device, index) => {
                    // Convert all values to lowercase for case-insensitive search
                    const lowerSearch = this.state.search.toLowerCase();
                
                    // Check if any column contains the search term
                    if (
                        (device.name && device.name.toLowerCase().includes(lowerSearch)) ||
                        (device.project && device.project.name && device.project.name.toLowerCase().includes(lowerSearch)) ||
                        (device.physical_id && device.physical_id.toLowerCase().includes(lowerSearch)) ||
                        (device.currentInstall && typeof device.currentInstall.version === 'string' && device.currentInstall.version.toLowerCase().includes(lowerSearch)) ||
                        (device.connected ? "Yes" : "No").toLowerCase().includes(lowerSearch)||
                        (device.location && device.location.toLowerCase().includes(lowerSearch))
                    ) {
                        return (
                            <li className='tsk-displays-row' key={device.id}>
                                <Link to={'/admin/displays/' + device.id} className={'tsk-displays-list-item'}>
                                    <div className='tsk-display-col-item'>{index + 1}</div>
                                    <div className='tsk-display-col-item'>{(device.name == null) ? 'No name' : device.name}</div>
                                    <div className='tsk-display-col-item'>{device.location == null ? 'No location': device.location}</div>
                                    <div className='tsk-display-col-item'>{(device.project == null) ? 'No project' : device.project ? device.project.name : 'No project'}</div>
                                    <div className='tsk-display-col-item'>{device.connected ? "Yes" : "No"}</div>
                                    <div className='tsk-display-col-item'>{device.physical_id}</div>
                                    <div className='tsk-display-col-item'>{(device.currentInstall == null) ? 'None' : device.currentInstall ? device.currentInstall.version : 'None'}</div>
                                    <div className='tsk-display-col-item'>{(device.devicesGroup) ? device.devicesGroup.name : 'No group'}</div>
                                </Link>
                            </li>
                        );
                    } else {
                        return null;
                    }
                    });
                    }
                }
            }
            </Query>
            </ul>
            </NeedAdmin>
            );
          }else{
            return (
            <NeedAdmin trueAdmin>
            <ul className='tsk-dispays-list-container'>
            <button className='tsk-btn-update' style={{width: '100px', margin: '20px'}} onClick={()=>{window.location.href = '/admin/displays'}}>Retour</button>
            <input className='tsk-input-search' type='text' placeholder='Search | Filter' value={this.state.search} onChange={(e)=>{this.setState({search: e.target.value})}}/>
            <li className='tsk-displays-row'>
            <div className={"tsk-displays-list-item"}>
            <div className='tsk-display-col-item bold'>Row</div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('name')}}><FormattedMessage id="displays.list.colone.name"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('location')}}><FormattedMessage id="displays.list.colone.location"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('project')}}><FormattedMessage id="displays.list.colone.current"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('connected')}}><FormattedMessage id="displays.list.colone.state"></FormattedMessage></div>
            <div className='tsk-display-col-item bold cursor-pointor' onClick={()=>{this.handleSort('physical_id')}}><FormattedMessage id="displays.list.colone.physical_id"></FormattedMessage></div>
            <div className='tsk-display-col-item bold'><FormattedMessage id="displays.list.colone.version"></FormattedMessage></div>
            <div className='tsk-display-col-item bold'>Device group</div>
            </div>
            </li>
            <Query query={queries.displays.list} variables={{id: groupId}}>
            {({loading, error, data})=>{
                if(loading){
                    return 'loading';
                }if(error){
                    return error.message
                }else{
                  console.log(data);
                  const sortedData = data.devices.slice().sort((a, b) => {
                    const aValue = a[this.state.sortColumn];
                    const bValue = b[this.state.sortColumn];
                
                    if(this.state.sortColumn === 'project'){
                      if (aValue !== null && bValue !== null) {
                        const aProjectName = a.project && a.project.name ? a.project.name : '';
                        const bProjectName = b.project && b.project.name ? b.project.name : '';
                
                        if (sortOrder === 'asc') {
                            return aProjectName.localeCompare(bProjectName);
                        } else {
                            return bProjectName.localeCompare(aProjectName);
                        }
                    } else {
                        return 0;
                      }
                    }
                    if (this.state.sortColumn === 'name' || this.state.sortColumn === 'physical_id') {
                      const aProjectName = aValue || 'no name';
                      const bProjectName = bValue || 'no name';
                  
                      if (sortOrder === 'asc') {
                        return aProjectName.localeCompare(bProjectName);
                      } else {
                        return bProjectName.localeCompare(aProjectName);
                      }
                    }

                    if(this.state.sortColumn === 'connected'){
                      const aProjectName = aValue ? 'yes' : 'no';
                      const bProjectName = bValue ? 'yes' : 'no';
                  
                      if (sortOrder === 'asc') {
                        return aProjectName.localeCompare(bProjectName);
                      } else {
                        return bProjectName.localeCompare(aProjectName);
                      }
                    }
                });
                
                return sortedData.map((device, index) => {
                    // Convert all values to lowercase for case-insensitive search
                    const lowerSearch = this.state.search.toLowerCase();
                
                    // Check if any column contains the search term
                    if (
                        (device.name && device.name.toLowerCase().includes(lowerSearch)) ||
                        (device.project && device.project.name && device.project.name.toLowerCase().includes(lowerSearch)) ||
                        (device.physical_id && device.physical_id.toLowerCase().includes(lowerSearch)) ||
                        (device.currentInstall && typeof device.currentInstall.version === 'string' && device.currentInstall.version.toLowerCase().includes(lowerSearch)) ||
                        (device.connected ? "Yes" : "No").toLowerCase().includes(lowerSearch) ||
                        (device.location && device.location.toLowerCase().includes(lowerSearch))
                    ) {
                        return (
                            <li className='tsk-displays-row' key={device.id}>
                                <Link to={'/admin/displays/' + device.id} className={'tsk-displays-list-item'}>
                                    <div className='tsk-display-col-item'>{index + 1}</div>
                                    <div className='tsk-display-col-item'>{(device.name == null) ? 'No name' : device.name}</div>
                                    <div className='tsk-display-col-item'>{device.location == null ? 'No location': device.location}</div>
                                    <div className='tsk-display-col-item'>{(device.project == null) ? 'No project' : device.project ? device.project.name : 'No project'}</div>
                                    <div className='tsk-display-col-item'>{device.connected ? "Yes" : "No"}</div>
                                    <div className='tsk-display-col-item'>{device.physical_id}</div>
                                    <div className='tsk-display-col-item'>{(device.currentInstall == null) ? 'None' : device.currentInstall ? device.currentInstall.version : 'None'}</div>
                                    <div className='tsk-display-col-item'>{(device.devicesGroup) ? device.devicesGroup.name : 'No group'}</div>
                                </Link>
                            </li>
                        );
                    } else {
                        return null;
                    }
                    });
                    }
                }
            }
            </Query>
            </ul>
            </NeedAdmin>
            )
          }
        }
    }

export default DisplaysList;