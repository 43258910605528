import gql from "graphql-tag";

export const list = gql`
  {
    screenTypes {
      id
      name
      x_dimension
      y_dimension
    }
  }
`;
