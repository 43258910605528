import React from 'react';
import {backEndUri} from "../../config";
import Dropzone from "react-dropzone";

class FileUploader extends React.Component {

  static defaultProps = {
    maxSize: 20971520
  };

  onDrop = async (files) => {
    const {onUpload, checksOnUpload, uploadPath, cellId} = this.props;
    const file = files[0];

    if(!!checksOnUpload) {
      const checkResult = await checksOnUpload();
      if (!checkResult) return;
    }
    if (!file) return;

    console.log('files=', files);

    const data = new FormData();
    data.append('file', file);
    data.append('filename', file.name);
    data.append('cellId', cellId);

    const uploadResponse = await fetch(
      `${backEndUri}${!!uploadPath ? uploadPath : ''}`,
      { method: 'POST', body: data }
    );

    const jsonResponse = await uploadResponse.json();
    await onUpload(jsonResponse.uri);

    console.log('File uploaded!');
  };

  render() {
    const {maxSize, accept} = this.props;
    return (
      <Dropzone
        multiple={false}
        onDrop={this.onDrop}
        minSize={0}
        maxSize={maxSize}
        {...(!!accept ? {accept} : {})}
      >
        {({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles}) => {
          const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
          if (rejectedFiles.length > 0) {
            console.log('Drop rejected file');
            if (isFileTooLarge)
              console.log('File is too large');
            else
              console.log(`Mime type '${rejectedFiles[0].type}' do not match regex: ${JSON.stringify(accept)}`);
          }
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive && 'Click here or drop a file to upload!'}
              {isDragActive && !isDragReject && "Drop it like it's hot!"}
              {isDragReject && "File type not accepted, sorry!"}
              {isFileTooLarge && (
                <div className="tsk-error">
                  File is too large.
                </div>
              )}
            </div>
          )}
        }
      </Dropzone>
    )
  }
}

export default FileUploader;