import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateVideoCell ($input: UpdateVideoCellInput!) {
      updateVideoCell (input: $input) {
        id
        cell_type
        video_uri
        poster_uri
        i18n {
          language_code
          video_uri
          poster_uri
        }
      }
    }`,

  get: gql`
    query VideoCell($id: ID!) {
      videoCell(id: $id) {
        id
        video_uri
        poster_uri
        i18n {
          language_code
          video_uri
          poster_uri
        }
      }
    }`
}
