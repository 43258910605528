import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {WheelCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class WheelCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={['far', 'image']} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.wheel.get}
        renderingCondition={(wheelCell) => !!wheelCell.wheel_uri}
      >
        {(wheelCell) => <WheelCell preview={true} cell={wheelCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default WheelCellPreview;
