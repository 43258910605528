import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateImageCell ($input: UpdateImageCellInput!) {
      updateImageCell (input: $input) {
        id
        cell_type
        image_uri
        i18n {
          language_code
          image_uri
        }
      }
    }`,

  get: gql`
    query ImageCell($id: ID!) {
      imageCell(id: $id) {
        id
        image_uri
        i18n {
          language_code
          image_uri
        }
      }
    }`
}
