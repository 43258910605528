import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {PlanCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class PlanCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={['far', 'image']} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.plan.get}
        renderingCondition={(planCell) => !!planCell.plan_uri}
      >
       {(planCell) => <PlanCell preview={true} cell={planCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default PlanCellPreview;
