import React, {Fragment} from 'react';
import { withRouter, Link } from 'react-router-dom';
import GridLayout from 'react-grid-layout';
import {FormattedMessage, injectIntl} from "react-intl";
import {Query} from "react-apollo";
import { find, isEqualWith } from 'lodash';

import {Background, utils} from "@treeosk/common"

import { apolloClient } from '../../config';
import '../../style/PageEdition.scss'
import queries from "../../queries";
import CellToolbox from "../cells/CellToolbox";
import CellEditor from "../cells/editors/CellEditor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageSettings from "./PageSettings";
import CellPreview from "../cells/previews/CellPreview";
import confirmDialog from "../dialogs/ConfirmDialog";
import ProjectSettings from "../projects/ProjectSettings";
import NeedAdmin from "../NeedAdmin";
import {identityProvider, isUserPowerUser} from "../../utils";

class PageEdition extends React.Component {

  state = {
    elements: {},
    view: this.props.match.params.cellId ? 'module' : 'toolbox'
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevCellId = prevProps.match.params.cellId;
    const newCellId = this.props.match.params.cellId;
    if (!!newCellId) {
      if (!prevCellId || prevCellId !== newCellId) {
        this.setState({view: 'module'});
      }
    } else if (this.state.view === 'module') {
      this.setState({view: 'toolbox'});
    }
  }

  render() {
    const { intl, screenType, fontRatio } = this.props;
    const { projectId, pageId, cellId } = this.props.match.params;
    const { view } = this.state;

    const isPowerUser = isUserPowerUser(identityProvider.currentUser());

    console.log(`Editing page #${pageId} for screenType #${screenType.id}`);

    const viewChangeButton = (viewName) => {
      return (
        <button
          onClick={() => this.setState({view: viewName})}
          className={`${view === viewName ? 'current' : ''} tsk-button`}
        >
          <FormattedMessage id={`page.editor.button.${viewName}`} />
        </button>
      )
    };

    const pageEditorView = (viewName, params) => {
      switch(viewName) {
        case 'toolbox':
          return (
            <NeedAdmin>
              <CellToolbox {...params}/>
            </NeedAdmin>
          );
        case 'module':
          return <CellEditor {...params}/>;
        case 'page':
          return <PageSettings {...params}/>;
        case 'project':
          return <ProjectSettings {...params}/>;
        default:
          return <span>{viewName}</span>;
      }
    };

    const deleteDialogConfirmationContent = (
        <FormattedMessage id={'page.editor.deletecell.dialog.message'} values={{br: <br/>}} />
    );

    return (
      <Query query={queries.pages.getWithChildren} variables={{ id: pageId, screen_type_id: screenType.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { page } = data;
          const layout = page.cells.map((cell)=> (
            {
              i: cell.id,
              x: cell.coordinates.x,
              y: cell.coordinates.y,
              w: cell.coordinates.width,
              h: cell.coordinates.height
            }
          ));

          function getCellById(cellId) {
            return find(page.cells, {'id': cellId});
          }

          const nbCols = 4*screenType.x_dimension;
          const minRows = 4*screenType.y_dimension;
          const dimensionSize = 10;
          const width = nbCols * dimensionSize;
          const height = minRows * dimensionSize;

          const borderStyle = {
            position: "absolute",
            width,
            height,
            border: "1px black solid",
            zIndex: 50,
            pointerEvents: "none"
          };

          const bgStyle = {
            position: "relative",
            width,
            height,
          };

          const fontSize = utils.defaultFontSize(screenType, width, height, fontRatio);

          return(
            <div className='tsk-page-edit-container' style={{"--default_font_size": `${fontSize}px`}}>
              <Background className="tsk-page-grid" value={page} style={bgStyle}>
                <Fragment>
                  <div style={borderStyle} />
                  <GridLayout
                              layout={layout}
                              margin={[0,0]}
                              cols={nbCols}
                              rowHeight={dimensionSize}
                              width={nbCols*dimensionSize}
                              isDraggable={isPowerUser}
                              isResizable={isPowerUser}
                              onLayoutChange={async (newLayout) => {
                                if(screenType.id !== this.props.screenType.id) {
                                  // We are just changing screen type, nothing to save
                                  return;
                                }
                                const cellsToUpdate = [];
                                const cellElemComparator = (cell, elem) => {
                                  if (cell.id !== elem.i) return false;
                                  if (cell.x !== elem.x) return false;
                                  if (cell.y !== elem.y) return false;
                                  if (cell.width !== elem.w) return false;
                                  return cell.height === elem.h;
                                };
                                for (const newElem of newLayout) {
                                  const prevElem = getCellById(newElem.i);
                                  if(!!prevElem && !isEqualWith(prevElem, newElem, cellElemComparator)) {
                                    const updatedCell = {
                                      cell_id: newElem.i,
                                      x: newElem.x,
                                      y: newElem.y,
                                      width: newElem.w,
                                      height: newElem.h
                                    };
                                    cellsToUpdate.push(updatedCell);
                                  }
                                }

                                if(cellsToUpdate.length > 0) {
                                  await apolloClient.mutate({
                                    mutation: queries.cells.updateCellsCoordinates,
                                    variables: {input: cellsToUpdate, screenTypeId: screenType.id},
                                    ignoreResults: true,
                                    refetchQueries: () => [{ query: queries.pages.getWithChildren, variables: {id: pageId, screen_type_id: screenType.id} }]
                                  });
                                }
                              }}
                  >
                    {page.cells.map((cell)=> {
                      return (
                        <Link key={cell.id} to={`/project/${projectId}/page/${pageId}/cell/${cell.id}`} className={cell.id === cellId && 'current'}>
                          <div className={"tsk-cell-preview"}>
                            <CellPreview cell={cell} screenType={screenType} />
                          </div>
                          <NeedAdmin>
                            <span
                              className={'tsk-delete-icon tsk-cell'}
                              onClick={async (e) => {
                                e.preventDefault();
                                await confirmDialog(deleteDialogConfirmationContent).then(
                                  async (result) => {
                                    console.log('deleting cell');
                                    await apolloClient.mutate({
                                      mutation: queries.cells.deleteCell,
                                      variables: {input: cell.id},
                                      refetchQueries: () => [{ query: queries.pages.getWithChildren, variables: {id: pageId, screen_type_id: screenType.id} }]
                                    })
                                  }
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={'trash-alt'} />
                            </span>
                          </NeedAdmin>
                        </Link>
                      )
                    })}
                  </GridLayout>
                </Fragment>
              </Background>

              <div className='tsk-page-editor'>
                <div className='tsk-page-editor-head'>
                  <NeedAdmin>
                    {viewChangeButton('toolbox')}
                  </NeedAdmin>
                  {!!cellId && viewChangeButton('module')}
                  <NeedAdmin>
                    {viewChangeButton('page')}
                    {viewChangeButton('project')}
                  </NeedAdmin>
                </div>
                <div className='tsk-page-editor-content'>
                  {pageEditorView(view, {cell: getCellById(cellId), page, screenType})}
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    );
  }
}

export default injectIntl(withRouter(PageEdition));
