import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {IframeCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class IframeCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={'external-link-alt'} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.iframe.get}
        renderingCondition={(iframeCell) => !!iframeCell.uri}
      >
        {(iframeCell) => <IframeCell preview={true} cell={iframeCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default IframeCellPreview;
