import React from "react";
import {Mutation} from "react-apollo";
import _ from "lodash";
import queries from "../../../queries";
import {stateOrValue} from "../../../utils";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MultipleChoisesEditor from "./MultipleChoisesEditor";

class FieldEditor extends React.Component {
  state = {
    i18n: {}
  };
  
  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }
  
  render() {
    const {field, language, cell, screenType} = this.props;
    if(!!language) {
      const i18nState = this.state.i18n[language.code] || {};
      const i18nValues = _.filter(field.i18n, {language_code: language.code})[0] || {};
      return (
        <Mutation
        mutation={queries.form_fields.updateI18n}
        refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
        >
        {(i18nFieldUpdate) => (
          <div className={'tsk-input-line'}>
          <label htmlFor={`${field.id}_label`}>{field.name}</label>
          <input
          name={`${field.id}_label`}
          value={stateOrValue(i18nState.label, i18nValues.label, {defaultValue: ''})}
          onChange={(e) => {
            this.setI18nState(language, i18nState, {label: e.target.value, touched: true})
          }
        }/>
        
        {
          (field.type === "multiple_choices" || field.type === "select") ?
          <MultipleChoisesEditor field={field} cell_id={cell.id} screen_type_id={screenType.id} language_code={language.code}/>
          :
          ''
        }
        
        {i18nState.touched &&
          <button className={'tsk-button'}
          onClick={() => {
            this.setI18nState(language, i18nState, {touched: false});
            i18nFieldUpdate({variables: {input: {
              form_field_id: field.id,
              language_code: language.code,
              label: i18nState.label,
            }}});
          }
        }>
        <FormattedMessage id={"common.button.save"} />
        </button>
      }
      </div>
      )}
      </Mutation>
      )
    }
    
    return (
      <Mutation
      mutation={queries.form_fields.update}
      refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell.id, screen_type_id: screenType.id} }]}
      >
      {(updateField) => (
        <div className={'tsk-input-line'}>
        <label htmlFor={`${field.id}_label`}>{field.name}</label>
        <input
        name={`${field.id}_label`}
        value={stateOrValue(this.state.label, cell.label, {defaultValue: ''})}
        onChange={(e) => {
          this.setState({label: e.target.value, touched: true})
        }
      }/>
      {stateOrValue(this.state.visible, field.visible) &&
        <span onClick={() => {
          this.setState({visible: false, touched: true});
        }}>
        <FontAwesomeIcon icon={'eye'}/>
        </span>
      }
      {!stateOrValue(this.state.visible, field.visible) &&
        <span onClick={() => {
          this.setState({visible: true, touched: true});
        }}>
        <FontAwesomeIcon icon={'eye-slash'}/>
        </span>
      }
      {
        (field.type === "multiple_choices" || field.type === "select") ?
        <MultipleChoisesEditor field={field} cell_id={cell.id} screen_type_id={screenType.id} language_code={null}/>
        :
        ''
      }
      {this.state.touched &&
        <button className={'tsk-button'}
        onClick={() => {
          this.setState({touched: false});
          updateField({variables: {input: {
            id: field.id,
            ...(this.state.label ? {label: this.state.label} : {}),
            ...(this.state.visible !== undefined ? {visible: this.state.visible} : {}),
          }}});
        }
      }>
      <FormattedMessage id={"common.button.save"} />
      </button>
    }
    </div>
    )}
    </Mutation>
    )
  }
}

export default FieldEditor;
