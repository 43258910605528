import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateFormCell ($input: UpdateFormCellInput!) {
      updateFormCell (input: $input) {
        id
        cell_type
        submit_button_text
        confirmation_text
        error_text
        border_color
        submit_text_color
        input_text_color
        confirmation_text_color
        error_text_color
        input_background_color
        submit_background_color
        font
        send_email
        from_email
        email_subject
        email_body
        redirect_page_id
        i18n {
          language_code
          submit_button_text
          confirmation_text
          error_text
          email_subject
          email_body
        }
      }
    }`,

  updateOption: `
    mutation UpdateFormFieldOption(input: $input){
      id
      name
      label
    }
  `,

  get: gql`
    query FormCell($id: ID!, $screen_type_id: ID!) {
      formCell(id: $id) {
        id
        cell_type
        submit_button_text
        confirmation_text
        error_text
        border_color
        submit_text_color
        input_text_color
        confirmation_text_color
        error_text_color
        input_background_color
        submit_background_color
        font
        send_email
        from_email
        email_subject
        email_body
        redirect_page_id
        i18n {
          language_code
          submit_button_text
          confirmation_text
          error_text
          email_subject
          email_body
        }
        coordinates(screen_type_id: $screen_type_id) {
          width
        }
        fields {
          id
          type
          name
          label
          required
          sort_order
          half_width
          visible
          options{
            id
            form_field_id
            name
            label
            i18n{
              language_code
              label
            }
          }
          i18n {
            language_code
            label
          }
        }
      }
    }`
}
