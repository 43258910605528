import React from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import VideoUploader from "../../uploaders/VideoUploader";
import _ from "lodash";
import ImageUploader from "../../uploaders/ImageUploader";
import {FormattedMessage} from "react-intl";
import confirmDialog from "../../dialogs/ConfirmDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class VideoCellEditor extends React.Component  {

  deleteDialogConfirmationContent = (
    <FormattedMessage id={'cell.video.poster.delete.confirmation.dialog.message'} values={{br: <br/>}} />
  );

  render() {
    const { cell, language } = this.props;

    return (
      <Query query={queries.cells.video.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { videoCell } = data;
          if (!videoCell) return null;

          const { videoUriValue, posterUriValue, mutation, baseInput } = !!language ?
            {
              videoUriValue: (_.filter(videoCell.i18n, {language_code: language.code})[0] || {}).video_uri,
              posterUriValue: (_.filter(videoCell.i18n, {language_code: language.code})[0] || {}).poster_uri,
              mutation: queries.cells.i18n.update,
              baseInput: {
                cell_id: cell.id,
                language_code: language.code,
              },
            } : {
              videoUriValue: videoCell.video_uri,
              posterUriValue: videoCell.poster_uri,
              mutation: queries.cells.video.update,
              baseInput: {
                id: cell.id,
              },
            };

          return (
            <Mutation
              mutation={mutation}
              refetchQueries={() => [{ query: queries.cells.video.get, variables: {id: cell.id} }]}
            >
              {(mutationFct) => (
                <>
                  <div className={'tsk-settings-block'}>
                    <label>
                      <FormattedMessage id={'cell.video.label'} />
                    </label>
                    <VideoUploader value={videoUriValue} onUpload={(videoUri) =>
                        mutationFct({ variables: { input: {...baseInput, video_uri: videoUri} }})
                      } />
                  </div>
                  <div className={'tsk-settings-block'}>
                    <label>
                      <FormattedMessage id={'cell.video.poster.label'} />
                      {posterUriValue &&
                        <button type={'button'} className={'tsk-button tsk-link tsk-main-page-button'} onClick={() => {
                          confirmDialog(this.deleteDialogConfirmationContent).then(res =>
                            mutationFct({ variables: { input: {...baseInput, poster_uri: null} }})
                          );
                        }}>
                          &nbsp;<FontAwesomeIcon icon={'trash-alt'}/>
                        </button>
                      }
                    </label>
                    <ImageUploader value={posterUriValue} onUpload={(imageUri) =>
                      mutationFct({ variables: { input: {...baseInput, poster_uri: imageUri} }})
                    } />
                  </div>
                </>
              )}
            </Mutation>
          );

        }}
      </Query>
    );
  }
}

export default VideoCellEditor;
