import React from 'react';

import {QuizzCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class QuizzCellPreview extends React.Component {

  render() {
    const { cell } = this.props;

    return (
      <SpecificCellPreview
        cell={cell}
        query={queries.cells.quizz.get}
        renderingCondition={(textCell) => !!textCell.text_data}
      >
        {(quizzCell) =>
          <QuizzCell preview={true} cell={quizzCell} />
        }
      </SpecificCellPreview>
    );
  }

}

export default QuizzCellPreview;