import React from 'react';
import {FormattedMessage} from "react-intl";
import {stateOrValue} from "../utils";
import PaletteColorPicker from "./PaletteColorPicker";
import ImageUploader from "./uploaders/ImageUploader";
import VideoUploader from "./uploaders/VideoUploader";
import _ from "lodash";

class BackgroundSelector extends React.Component {

  state = {};

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onChange } = this.props;
    if (!_.isEqual(prevProps.value, this.props.value)) {
      const emptyState = _.mapValues(this.state, () => undefined);
      this.setState(emptyState);
    } else if (!_.isEqual(prevState, this.state)) {
      onChange(this.state);
    }
  }

  render() {
    const { value, palette } = this.props;

    return (
      <div className={'tsk-setting-section'}>
        <label><FormattedMessage id={'cell.text.background.section.label'} /></label>
        <ul>
          <li>
            <div className={'tsk-input-line'} onClick={(e) => {
              this.setState({backgroundType: 'none'});
            }}>
              <input type="radio" name="backgroundType" value="none"
                     checked={stateOrValue(this.state.backgroundType, value.background_type, {transform: (val) => val === 'none'})}
                     onChange={() => {}}
              />
              <label><FormattedMessage id={'cell.text.background.color.none'} /></label>
            </div>
          </li>
          <li>
            <div className={'tsk-input-line'} onClick={(e) => {
              this.setState({backgroundType: 'color'});
            }}>
              <input type="radio" name="backgroundType" value="color"
                     checked={stateOrValue(this.state.backgroundType, value.background_type, {transform: (val) => val === 'color'})}
                     onChange={() => {}}
              />
              <label htmlFor={'backgroundColor'}><FormattedMessage id={'cell.text.background.color.label'} /></label>
              <PaletteColorPicker palette={palette} color={value.background_color || palette[0]} disableAlpha={true} onChange={(color) => {
                const rgb = color.rgb;
                this.setState({backgroundColor : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
              }} />
            </div>
          </li>
          <li>
            <div className={'tsk-input-line'} onClick={(e) => {
              this.setState({backgroundType: 'image'});
            }}>
              <input type="radio" name="backgroundType" value="image"
                     checked={stateOrValue(this.state.backgroundType, value.background_type, {transform: (val) => val === 'image'})}
                     onChange={() => {}}
              />
              <label htmlFor={'backgroundImage'}><FormattedMessage id={'cell.text.background.image.label'} /></label>
              <ImageUploader
                value={stateOrValue(this.state.backgroundImageUri, value.background_image_uri)}
                onUpload={(imageUri) => {
                  this.setState({backgroundImageUri : imageUri});
                }}
              />
            </div>
          </li>
          <li>
            <div className={'tsk-input-line'} onClick={(e) => {
              this.setState({backgroundType: 'video'});
            }}>
              <input type="radio" name="backgroundType" value="video"
                     checked={stateOrValue(this.state.backgroundType, value.background_type, {transform: (val) => val === 'video'})}
                     onChange={() => {}}
              />
              <label htmlFor={'backgroundVideo'}><FormattedMessage id={'cell.text.background.video.label'} /></label>
              <VideoUploader
                value={stateOrValue(this.state.backgroundVideoUri, value.background_video_uri)}
                onUpload={(videoUri) => {
                  this.setState({backgroundVideoUri : videoUri});
                }}
              />
            </div>
          </li>
        </ul>
      </div>
    )
  }
}

export default BackgroundSelector;
