export default {
  init: () => {},
  on: () => {},
  currentUser: () => ({
    app_metadata: {
      roles: ["admin"]
    },
    jwt: async () => {},
  })
}
