import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateTabMenuCell ($input: UpdateTabMenuCellInput!) {
      updateTabMenuCell (input: $input) {
        id
        cell_type

        tab_menu_id
        current_item_id
      }
    }`,

  get: gql`
    query TabMenuCell($id: ID!) {
      tabMenuCell(id: $id) {
        id
        cell_type

        tab_menu_id
        current_item_id
      }
    }`
}
