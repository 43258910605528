import React from 'react';
import {isUserAdmin, identityProvider, isUserPowerUser} from "../utils";

class NeedAdmin extends React.Component {

  state = {};

  componentDidMount() {
    this.setState({
      isAdmin: isUserAdmin(identityProvider.currentUser()),
      isPowerUser: isUserPowerUser(identityProvider.currentUser()),
    });
    identityProvider.on("login", () =>
      this.setState({
        isAdmin: isUserAdmin(identityProvider.currentUser()),
        isPowerUser: isUserPowerUser(identityProvider.currentUser()),
      })
    );
  }

  render() {
    const {isAdmin, isPowerUser} = this.state;
    if (!!isAdmin) return this.props.children;
    if (!!isPowerUser && !this.props.trueAdmin) return this.props.children;
    return null
  }
}

export default NeedAdmin
