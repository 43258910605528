import gql from "graphql-tag";

export const getGameById = gql`
query Game($id: ID!) {
  game(id: $id) {
    id
    estimated_nb_players
    default_prize{
        id
        slot_index
    }
    game_type
    prizes{
      id
      slot_index
      image_uri
      label
      periodes{
        id
        prize_id
        qts_for_periode
        periode_start
        periode_end
      }
    }
  }
}
`;

export const getGameProjectId = gql`
query Games($project_id: ID!) {
  gamesForProjectId(project_id: $project_id) {
    id
    estimated_nb_players
    game_type
    default_prize{
      id
      label
    }
  }
}
`;

export const update = gql`
mutation updateGame ($input: UpdateGameInput!) {
  updateGame (input: $input) {
    id
    estimated_nb_players
  }
}
`;

export const create = gql`
mutation createGamePrize ($input: CreateGamePrizeInput!) {
  createGamePrize (input: $input) {
    id
    slot_index
  }
}
`
export const deletePrize = gql`
  mutation deletePrize ($id: ID!){
    deletePrize(id: $id){
      id
    }
  }
`
export const updatePrizes = gql`
mutation updateGamePrize ($input: UpdateGamePrizeInput!){
  updateGamePrize(input: $input){
    image_uri
  }
}
`
export const createPeriode = gql`
  mutation createPeriodePrize($prize_id: ID!){
    createPeriodePrize(prize_id: $prize_id){
      id
      prize_id
      qts_for_periode
    }
  }
`

export const getPrizePeriode = gql`
query PeriodePrizes($prize_id: ID!){
  prizePeriodes(prize_id: $prize_id){
    id
    prize_id
    qts_for_periode
    periode_start
    periode_end
  }
}
`
export const updatePeriode = gql`
  mutation updatePeriodePrize($input: UpdatePrizePeriodeInput!){
    updatePeriodePrize(input: $input){
      id
      prize_id
      qts_for_periode
      periode_start
      periode_end
    }
  }
`
export const deletePeriode = gql`
mutation deletePeriodePrize($id: ID!){
  deletePeriodePrize(id: $id){
    id
  }
}
`