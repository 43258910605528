import gql from "graphql-tag";

export default {
  get: gql`
    query QuizzCell($id: ID!) {
      quizzCell(id: $id) {
        id
        question_timer
        text_color
        background_color
        border_color
      }
    }`
}
