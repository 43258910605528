import React from "react";
import {injectIntl} from "react-intl";

class IntlOption extends React.PureComponent {
  render() {
    const {value, id, intl} = this.props;
    const txt = intl.formatMessage({id});
    return(
      <option value={value}>{txt}</option>
    )
  }
}

export default injectIntl(IntlOption);
