import React from "react";
import {usersListMockData} from "../config";
import { identityProvider } from '../utils';
import {isUserAdmin} from "../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage} from "react-intl";
import {Query} from "react-apollo";
import queries from "../queries";
import _ from "lodash";
import Select from "react-select";
import NeedAdmin from "./NeedAdmin";

class UsersManagement extends React.Component {

  state = {
    userEdition: []
  };

  componentDidMount() {
    const user = identityProvider.currentUser();
    if(!user) {
      identityProvider.on("login", () => this.loadUsersData());
      return;
    }
    identityProvider.on("logout", () => this.setState({ users: undefined }));
    this.loadUsersData();
  }

  loadUsersData() {
    if(!!usersListMockData) {
      this.setState({users: usersListMockData});
      return;
    }
    identityProvider.currentUser().jwt().then((authToken) => {
      const usersListUri = `/.netlify/functions/listUsers`;
      fetch(usersListUri, {
          method: 'get',
          headers: new Headers({
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }),
      })
      .then(response =>response.json())
      .then(jsResponse => {
        this.setState({users: jsResponse});
      });
    });
  }

  updateUser(user, updateData) {
    console.log(`Updating user '${user.email}' with: ${JSON.stringify(updateData)}`);
    identityProvider.currentUser().jwt().then((authToken) => {
      const usersListUri = `/.netlify/functions/updateUser`;
      fetch(usersListUri, {
          method: 'post',
          headers: new Headers({
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }),
        body: JSON.stringify({userId: user.id, ...updateData}),
      })
      .then(() => {
        this.setState({
            userEdition: {
              ...this.state.userEdition,
              [user.id]: undefined
            }
          },
          this.loadUsersData.bind(this)
        );
      })
    });
  }

  createUser(email, password) {
    console.log(`Creating a new user: ${email}`);
    identityProvider.currentUser().jwt().then((authToken) => {
      const usersListUri = `/.netlify/functions/createUser`;
      fetch(usersListUri, {
        method: 'post',
        headers: new Headers({
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({email, password}),
      })
        .then(() => {
          this.setState({
              creationInProgress: false,
              newUserEmail: undefined,
              newUserPassword: undefined
            },
            this.loadUsersData.bind(this)
          );
        });
    });
  }

  projectById(projects, projectId) {
    return _.filter(projects, {id: projectId})[0];
  }

  render() {

    const {users, creationInProgress, newUserEmail, newUserPassword, userEdition} = this.state;
    if (!users) return <div>Loading...</div>;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: 'black',  // Couleur noire pour les options
      }),
    };

    return (
      <NeedAdmin trueAdmin>
      <Query query={queries.projects.list}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          const {projects} = data;

          return (
            <table>
              <thead>
                <tr>
                  <th><FormattedMessage id={"admin.userlist.email"}/></th>
                  <th><FormattedMessage id={"admin.userlist.isadmin"}/></th>
                  <th><FormattedMessage id={"admin.userlist.password"}/></th>
                  <th><FormattedMessage id={"admin.userlist.projects"}/></th>
                  <th/>
                </tr>
              </thead>
              <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.email}</td>
                  <td>
                    {isUserAdmin(user) ?
                      <FontAwesomeIcon icon={['far', 'check-square']}/>
                      :
                      <FontAwesomeIcon icon={['far', 'square']}/>
                    }
                  </td>
                  <td>
                    <input type={"password"} defaultValue={"password"}
                           onFocus={(e) => {
                             const elem = e.target;
                             elem.type = "text";
                             elem.value = "";
                           }}
                           onBlur={(e) => {
                             const elem = e.target;
                             if (elem.value === "") {
                               elem.type = "password";
                               elem.value = "password";
                             }
                           }}
                           onChange={(e) =>
                             this.setState({
                               userEdition: {
                                 ...userEdition,
                                 [user.id]: {
                                   ...userEdition[user.id],
                                   password: e.target.value
                                 }
                               }
                             })
                           }
                    />
                  </td>
                  {!isUserAdmin(user) &&
                  <td>
                    <Select
                      isClearable={true}
                      isMulti={true}
                      getOptionLabel={(role) => {
                        if (role === 'poweruser') return '*PowerUser*';
                        const project = this.projectById(projects, role);
                        if (!project) return "*DELETED*";
                        return project.name;
                      }}
                      getOptionValue={(projectId) => projectId}
                      value={
                        userEdition[user.id] ? userEdition[user.id].roles ? userEdition[user.id].roles :
                        user.app_metadata.roles : user.app_metadata.roles
                      }
                      onChange={(newRoles) =>
                        this.setState({
                          userEdition: {
                            ...userEdition,
                            [user.id]: {
                              ...userEdition[user.id],
                              roles: newRoles
                            }
                          }
                        })
                      }
                      styles={customStyles}  // Appliquer le style personnalisé
                      options={[...projects.map((p) => p.id), "poweruser"]}
                    />
                  </td>
                  }
                  <td>
                    {!!userEdition[user.id] &&
                      <button className={'tsk-button'}
                              onClick={() => this.updateUser(user, userEdition[user.id]) }>
                        <FormattedMessage id={"common.button.submit"} />
                      </button>
                    }
                  </td>

                </tr>
              ))}
              {!!creationInProgress &&
              <tr>
                <td>
                  <input type={"email"} placeholder={"email"} name={"email"}
                         value={newUserEmail}
                         onChange={(e) => {
                          this.setState({newUserEmail: e.target.value});
                        }}
                  />
                </td>
                <td>
                  <FontAwesomeIcon icon={['far', 'square']}/>
                </td>
                <td>
                  <input type={"text"} placeholder={"password"} name={"password"}
                         value={newUserPassword}
                         onChange={(e) => this.setState({newUserPassword: e.target.value}) }
                  />
                </td>
                <td>
                  <button className={'tsk-button'}
                          disabled={!newUserPassword || !newUserEmail}
                          onClick={() => this.createUser(newUserEmail, newUserPassword) }>
                    <FormattedMessage id={"common.button.submit"} />
                  </button>
                </td>
              </tr>
              }
              <tr>
                <td>
                  {!creationInProgress &&
                  <button className={'tsk-button'} onClick={() => this.setState({creationInProgress: true}) }>
                    <FormattedMessage id={"common.button.new"} />
                  </button>
                  }
                </td>
              </tr>
              </tbody>
            </table>
          );
        }}
      </Query>
      </NeedAdmin>
    );
  }
}

export default UsersManagement;
