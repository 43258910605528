import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ProductsListCell} from "@treeosk/common";

import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class ProductsListCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={['far', 'list-alt']} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.products_list.get}
      >
        {(plCell) => <ProductsListCell preview={true} cell={plCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default ProductsListCellPreview;
