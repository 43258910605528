import React, { useState } from "react"
import { Mutation } from "react-apollo";
import queries from "../../../queries";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

export default function MultipleChoisesEditor({field, screen_type_id, cell_id, language_code}){

    const [newField, setNewField] = useState('');

    const [optionsLabelsI18n, setOptionsLabelI18N] = useState(field.options.map((option)=>_.filter(option.i18n, {language_code: language_code})[0] || ""));

    if(language_code){
        return(
            <div className="tsk-update-ul">
                <ul>
                    {field.options.map((option, index)=>{
                        return(
                            <li key={option.id}>
                                <Mutation refetchQueries={() => [{ query: queries.cells.form.get, 
                                                                    variables: {id: cell_id, screen_type_id: screen_type_id} }]} 
                                                                    variables={{input: {id: option.id, language_code: language_code, 
                                                                    label: ((optionsLabelsI18n[index].label && !(typeof optionsLabelsI18n[index] == 'string')) ? 
                                                                    optionsLabelsI18n[index].label : optionsLabelsI18n[index])}}} 
                                                                    mutation={queries.form_fields.updateI18nForOption}>
                                    {
                                        (updateLang)=>{
                                            console.log(optionsLabelsI18n, index);
                                            return(
                                                <>
                                                    <input type="text" value={(optionsLabelsI18n[index].label && 
                                                        !(typeof optionsLabelsI18n[index] == 'string')) ? 
                                                        optionsLabelsI18n[index].label : 
                                                        optionsLabelsI18n[index] } 
                                                        onChange={(e)=>{setOptionsLabelI18N(optionsLabelsI18n.map((elem, elemIndex)=>(index == elemIndex) ? e.target.value : elem))}} />
                                                    <button onClick={updateLang} className="tsk-button">
                                                        <FormattedMessage id={"display.mananagement.update"} />
                                                    </button>
                                                </>
                                            )
                                        }
                                    }
                                </Mutation>
                            </li>
                        )
                    })}
                    <Mutation refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell_id, screen_type_id: screen_type_id} }]} mutation={queries.form_fields.create_option}>
                        {
                            (create)=>{
                                return (
                                    <li>
                                        <input type="text" value={newField} placeholder="New field" onChange={(e)=>{setNewField(e.target.value)}} />
                                        <button className="tsk-button" onClick={()=>{create({variables: {input: {name: newField, form_field_id: field.id}}})}}>
                                            <FormattedMessage id={"common.button.new"} />
                                        </button>
                                    </li>
                                )
                            }
                        }
                    </Mutation>
                </ul>
            </div>
        )
    }else{
        return(
        <div className="tsk-update-ul">
            <ul>
                {field.options.map((option)=>{
                    return(
                        <li key={option.id}>
                            <li key={option.id}>
                                {option.label}
                            </li>
                        </li>
                    )
                })}
                <Mutation refetchQueries={() => [{ query: queries.cells.form.get, variables: {id: cell_id, screen_type_id: screen_type_id} }]} mutation={queries.form_fields.create_option}>
                    {
                        (create)=>{
                            return (
                                <li>
                                    <input type="text" value={newField} placeholder="New field" onChange={(e)=>{setNewField(e.target.value)}} />
                                    <button className="tsk-button" onClick={()=>{create({variables: {input: {name: newField, form_field_id: field.id}}})}}>
                                        <FormattedMessage id={"common.button.new"} />
                                    </button>
                                </li>
                            )
                        }
                    }
                </Mutation>
            </ul>
        </div>
    )}
}