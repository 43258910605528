import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faFile as fasFile,
  faPlus,
  faHome,
  faParagraph,
  faTrashAlt,
  faFilm,
  faHandPointer,
  faLanguage,
  faArrowsAltV,
  faEllipsisH,
  faCocktail,
  faEye,
  faEyeSlash,
  faClipboardList,
  faGamepad,
  faExternalLinkAlt,
  faDharmachakra,
  faMap,
  faQuestion,
  faAward,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFile as farFile,
  faImage,
  faCopy,
  faAddressCard,
  faSquare,
  faCheckSquare,
  faImages,
  faListAlt,
  
} from '@fortawesome/free-regular-svg-icons';

library.add([
  fasFile,
  farFile,
  faImage,
  faPlus,
  faHome,
  faParagraph,
  faTrashAlt,
  faFilm,
  faHandPointer,
  faCopy,
  faLanguage,
  faArrowsAltV,
  faAddressCard,
  faSquare,
  faCheckSquare,
  faImages,
  faEllipsisH,
  faCocktail,
  faEye,
  faEyeSlash,
  faListAlt,
  faClipboardList,
  faGamepad,
  faExternalLinkAlt,
  faDharmachakra,
  faMap,
  faQuestion,
  faAward,
]);
