import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateEmailFormCell ($input: UpdateEmailFormCellInput!) {
      updateEmailFormCell (input: $input) {
        id
        cell_type
        submit_button_text
        confirmation_text
        error_text
        border_color
        submit_text_color
        input_text_color
        confirmation_text_color
        error_text_color
        input_background_color
        submit_background_color
        font
        from_email
        email_subject
        email_body
        i18n {
          language_code
          submit_button_text
          confirmation_text
          error_text
          email_subject
          email_body
        }
      }
    }`,

  get: gql`
    query EmailFormCell($id: ID!, $screen_type_id: ID!) {
      emailFormCell(id: $id) {
        id
        submit_button_text
        confirmation_text
        error_text
        border_color
        submit_text_color
        input_text_color
        confirmation_text_color
        error_text_color
        input_background_color
        submit_background_color
        font
        from_email
        email_subject
        email_body
        i18n {
          language_code
          submit_button_text
          confirmation_text
          error_text
          email_subject
          email_body
        }
        coordinates(screen_type_id: $screen_type_id) {
          width
        }
      }
    }`
}
