import netlifyIdentity from 'netlify-identity-widget';
import mockIdentityProvider from './mockIdentityProvider';

export const stateOrValue = (stateValue, cellValue, {defaultValue, transform = (val) => val === null ? '' : val} = {}) => {
  if(stateValue !== undefined) return transform(stateValue);
  if(cellValue !== undefined) return transform(cellValue);
  return defaultValue;
};

export const isUserAdmin = (user) => {
  return user && user.app_metadata.roles && user.app_metadata.roles.indexOf("admin") >= 0;
};

export const isUserPowerUser = (user) => {
  return user && user.app_metadata.roles && (user.app_metadata.roles.indexOf("admin") >= 0 || user.app_metadata.roles.indexOf("poweruser") >= 0);
};

export const identityProvider = process.env.REACT_APP_MOCK_LOGIN ? mockIdentityProvider : netlifyIdentity;

export const ellipsis = (txt, max=10) => txt.length > max ? `${txt.substring(0, max-1)}…` : txt;
