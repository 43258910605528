import React from 'react';

import FileUploader from "./FileUploader";

class VideoUploader extends React.Component {

  render() {
    const {maxSize, value, onUpload} = this.props;
    return (
      <div className={'tsk-videocell-preview'}>
        {value &&
          <video autoPlay muted loop src={value}>
            <source src={value} type="video/mp4" />
          </video>
        }
        <FileUploader
          accept={"video/mp4"}
          onUpload={onUpload}
          maxSize={maxSize}
          uploadPath={"/video"}
        />
      </div>
    )
  }
}

export default VideoUploader;
