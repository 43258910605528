import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {FormCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class FormCellPreview extends React.Component {

  render() {
    const { cell, screenType } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={'clipboard-list'} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.form.get}
        additionalVariables={{screen_type_id: screenType.id}}
      >
        {(formCell) => (
          <FormCell
            preview={true}
            cell={{...formCell, width: formCell.coordinates.width}}
            uriTransformer={(uri) => uri}

          />
        )}
      </SpecificCellPreview>
    );
  }

}

export default FormCellPreview;
