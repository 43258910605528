import React from 'react';

import {TextCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class TextCellPreview extends React.Component {

  render() {
    const { cell } = this.props;

    return (
      <SpecificCellPreview
        cell={cell}
        query={queries.cells.text.get}
        renderingCondition={(textCell) => !!textCell.text_data}
      >
        {(textCell) =>
          <TextCell preview={true} cell={textCell} />
        }
      </SpecificCellPreview>
    );
  }

}

export default TextCellPreview;
