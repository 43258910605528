import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {CarouselCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class CarouselCellPreview extends React.Component {

  render() {
    const { cell, screenType } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={'ellipsis-h'} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.carousel.get}
        additionalVariables={{screen_type_id: screenType.id}}
        renderingCondition={(carouselCell) => (!!carouselCell.slides && carouselCell.slides.length > 0)}
      >
        {(carouselCell) => (
          <CarouselCell
            preview={true}
            cell={{...carouselCell, height: carouselCell.coordinates.height}}
            uriTransformer={(uri) => uri}
            linkFromPageId={(pageId, children) => children}
            gridSize={10}
          />
        )}
      </SpecificCellPreview>
    );
  }

}

export default CarouselCellPreview;
