import React from 'react';
import PropTypes from 'prop-types';
import { createConfirmation, confirmable } from 'react-confirm';
import {FormattedMessage} from "react-intl";
import WithLang from "../WithLang";
import IntlOption from "../IntlOption";

class FieldCreationDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    options: PropTypes.object,       // arguments of your confirm function
  };

  state = {
    required: false,
  };

  render() {
    const {proceed, show, cancel, options} = this.props;
    const {name, type, required} = this.state;

    return (
      <WithLang>
        <div className={'tsk-dialog-container'} style={ show ? {} : {display : 'none'}}>
          <div className={'tsk-dialog'}>
            <div className={'tsk-dialog-content'}>
              <div className={'tsk-input-line'}>
                <label className='tsk-label-black' htmlFor={'field-name'}><FormattedMessage id={'form.field.creation.name.label'} />&nbsp;*</label>
                <input type={"text"} name={'field-name'} value={name || ''} onChange={(e) => this.setState({name: e.target.value})}/>
              </div>
              <div className={'tsk-input-line'}>
                <label className='tsk-label-black' htmlFor={'field-type'}><FormattedMessage id={'form.field.creation.type.label'} />&nbsp;*</label>
                <select value={type || ''} onChange={(e) => {
                  const newVal = e.target.value === '' ? undefined : e.target.value;
                  this.setState({type: newVal});
                }}>
                  <option value={''} />
                  <IntlOption value={'short_text'} id={'form.field.type.short_text.label'} />
                  <IntlOption value={'email'} id={'form.field.type.email.label'} />
                  <IntlOption value={'integer'} id={'form.field.type.integer.label'} />
                  <IntlOption value={'checkbox'} id={'form.field.type.checkbox.label'} />
                  <IntlOption value={'multiple_choices'} id={'form.field.type.multiple_choices.label'}/>
                  <IntlOption value={'date'} id={'form.field.type.date.label'}/>
                  <IntlOption value={'select'} id={'form.field.type.select.label'}/>
                </select>
              </div>
              <div className={'tsk-input-line'}>
                <label className='tsk-label-black' htmlFor={'field-required'}><FormattedMessage id={'form.field.creation.required.label'} /></label>
                <input type={"checkbox"} name={'field-required'} value={required} onChange={(e) => this.setState({required: e.target.checked})}/>
              </div>
            </div>
            <div className={'tsk-dialog-buttons'}>
              <button className={'tsk-button'} onClick={() => cancel()}>
                <FormattedMessage id={'dialog.cancel.button'} />
              </button>
              <button className={'tsk-button'} style={{marginLeft: '1em'}}
                      onClick={() => proceed({
                        cell_id: options.cell_id,
                        type,
                        name,
                        required,
                        label: name,
                        half_width: false,
                      })}
                      disabled={!name || !type}
              >
                <FormattedMessage id={'dialog.ok.button'} />
              </button>
            </div>
          </div>
        </div>
      </WithLang>
    )
  }
}

// create confirm function
const fieldCreationDialog = createConfirmation(confirmable(FieldCreationDialog));

export default (options = {}) => {
  return fieldCreationDialog({ options });
}