import gql from "graphql-tag";

export const create = gql`
  mutation CreateProject ($input: CreateProjectInput!) {
    createProject (input: $input) {
      id
      name
      color_palette
      default_button_text_color
      default_button_text_size
      default_button_font
      default_button_shape
      default_button_width_percentage
      default_button_opacity
      default_button_fit_whole_cell
      default_button_background_type
      default_button_background_image_uri
      default_button_background_color
      primary_language_code
      language_selector_shape
      language_selector_display_type
      language_selector_background_color
      language_selector_border_color
      language_selector_text_color
    }
  }`;

export const list = gql`
  {
    projects {
      id
      name
    }
  }
`;

export const get = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      name
      latest_version {
        version
        publication_date
        uri
      }
    }
  }
`;

export const getInstalls = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      name
      device_installs {
        device_id
        version
        install_date
      }
    }
  }
`;

export const update = gql`
  mutation UpdateProject ($input: UpdateProjectInput!) {
    updateProject (input: $input) {
      id
      name
      main_page_id
      color_palette
      default_button_text_color
      default_button_text_size
      default_button_font
      default_button_shape
      default_button_width_percentage
      default_button_opacity
      default_button_fit_whole_cell
      default_button_background_type
      default_button_background_image_uri
      default_button_background_color
      primary_language_code
      language_selector_shape
      language_selector_display_type
      language_selector_background_color
      language_selector_border_color
      language_selector_text_color
    }
  }
`;


export const getWithChildren = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      name
      main_page_id
      color_palette
      default_button_text_color
      default_button_text_size
      default_button_font
      default_button_shape
      default_button_width_percentage
      default_button_opacity
      default_button_fit_whole_cell
      default_button_background_type
      default_button_background_image_uri
      default_button_background_color
      primary_language_code
      language_selector_shape
      language_selector_display_type
      language_selector_background_color
      language_selector_border_color
      language_selector_text_color
      default_font_ratio
      languages {
        code
        name
        local_name
        flag_image_uri
      }
      pages {
        id
        name
      }
      fonts {
        id
        name
        uri
      }
      tab_menus {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;

export const getLanguagesForProject = gql`
  query Project($id: ID!){
    project(id: $id) {
      id
      languages {
        code
        name
        local_name
        flag_image_uri
      }
    }
  }
`

export const duplicate = gql`
  mutation DuplicateProject ($input: ID!) {
    duplicateProject (input: $input) {
      id
    }
  }`;

export const del = gql`
    mutation DeleteProject ($input: ID!) {
        deleteProject (input: $input) {
            id
            name
            color_palette
            default_button_text_color
            default_button_text_size
            default_button_font
            default_button_shape
            default_button_width_percentage
            default_button_opacity
            default_button_fit_whole_cell
            default_button_background_type
            default_button_background_image_uri
            default_button_background_color
            primary_language_code
            language_selector_shape
            language_selector_display_type
            language_selector_background_color
            language_selector_border_color
            language_selector_text_color
        }
    }`;
