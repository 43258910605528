import gql from "graphql-tag";

export const createProduct = gql`
    mutation ($input: CreateProductInput!) {
    createProduct (input: $input) {
      id
      name
      short_description
      price
      long_description
      small_image_uri
      large_image_uri
    }
  }`;

export const update = gql`
  mutation ($input: UpdateProductInput!) {
    updateProduct (input: $input) {
      id
      name
      short_description
      price
      long_description
      small_image_uri
      large_image_uri
    }
  }`;

export const get = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      name
      short_description
      price
      long_description
      small_image_uri
      large_image_uri
      i18n {
        language_code
        name
        short_description
        price
        long_description
        small_image_uri
        large_image_uri
      }
      categories {
        id
        name
      }
      visibility_periods {
        id
        visibility
        start_date
        end_date
      }
    }
  }`;

export const del = gql`
  mutation DeleteProduct ($input: ID!) {
    deleteProduct (input: $input) {
      id
      name
      short_description
      price
      long_description
      small_image_uri
      large_image_uri
    }
  }`;

export const setVisibilityPeriodsForProduct = gql`
  mutation ($productId: ID!, $input: [VisibilityPeriodInput!]!) {
    setVisibilityPeriodsForProduct(productId: $productId, input: $input) {
      id
      visibility
      start_date
      end_date
    }
  }`;

export const removeVisibilityPeriods = gql`
  mutation ($input: [ID!]!) {
    removeVisibilityPeriods (input: $input) {
      id
      visibility
      start_date
      end_date
    }
  }`;

export const listForProjectId = gql`
  query ProductsForProjectId($project_id: ID!, $category_ids:[ID!]) {
    productsForProjectId(project_id: $project_id, category_ids: $category_ids) {
      id
      name
      short_description
      price
      long_description
      small_image_uri
      large_image_uri
      i18n {
        language_code
        name
        short_description
        price
        long_description
        small_image_uri
        large_image_uri
      }
      categories {
        id
        name
      }
      visibility_periods {
        id
        visibility
        start_date
        end_date
      }
    }
  }`;

export const updateI18n = gql`
  mutation UpdateI18nProduct ($input: ProductI18nInput!) {
    i18nProduct (input: $input) {
      language_code
      name
      short_description
      price
      long_description
      small_image_uri
      large_image_uri
    }
  }`;
