import React from 'react';
import {Query} from "react-apollo";

class SpecificCellPreview extends React.Component {

  static defaultProps = {
    defaultRender: (cell) => <span>{cell.cell_type}</span>,
    renderingCondition: () => true
  };

  render() {
    const { cell, defaultRender, query, renderingCondition, children, additionalVariables = {} } = this.props;
    return (
      <Query query={query} variables={{ ...additionalVariables, id: cell.id }}>
        {({loading, error, data}) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const specificCell = data[Object.keys(data)[0]];
          if (!specificCell || !renderingCondition(specificCell)) {
            return (
              <div className={"tsk-placeholder-cell-preview"}>
                {defaultRender(cell)}
              </div>
            );
          }
          return children(specificCell);
        }}
      </Query>
    );
  }
}

export default SpecificCellPreview;
