import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

class NeedAuth extends React.Component {

  state = { loggedIn: false };

  handleLogInClick() {
    const {identityProvider} = this.props;
    identityProvider.open();
  }

  afterLogIn() {
    const {identityProvider} = this.props;
    identityProvider.close();
    this.setState({ loggedIn: true });
  }

  componentDidMount() {
    const {identityProvider} = this.props;
    identityProvider.on("login", () => this.afterLogIn());
    identityProvider.on("logout", () => this.setState({ loggedIn: false }));
  }


  render() {
    const {children, identityProvider, showLoginButton = true} = this.props;
    const user = identityProvider.currentUser();
    if (!user) {
      return !!showLoginButton ? (
        <div className={'tsk-login-box'}>
          <FormattedMessage id={'unauthenticated.access.denied'} />
          <div className={'tsk-bottom-buttons'}>
          <button className={'tsk-button'} onClick={ () => this.handleLogInClick() }>
            <FormattedMessage id={'login.button'} />
          </button>
          </div>
        </div>
      ) : null;
    }
    user.jwt(); // Renew jwt token!

    return children
  }
}

NeedAuth.propTypes = {
  identityProvider: PropTypes.object.isRequired,
  showLoginButton: PropTypes.bool
};

export default NeedAuth
