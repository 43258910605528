import React from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import ImageUploader from "../../uploaders/ImageUploader";
import _ from "lodash";

class ImageCellEditor extends React.Component  {

  render() {
    const { cell, language } = this.props;

    return (
      <Query query={queries.cells.image.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { imageCell } = data;
          if (!imageCell) return null;

          const { imageUriValue, mutation, mutationInputFromUri } = !!language ?
            {
              imageUriValue: (_.filter(imageCell.i18n, {language_code: language.code})[0] || {}).image_uri,
              mutation: queries.cells.i18n.update,
              mutationInputFromUri: (imageUri) => ({
                cell_id: cell.id,
                language_code: language.code,
                image_uri: imageUri,
              })
            } : {
              imageUriValue: imageCell.image_uri,
              mutation: queries.cells.image.update,
              mutationInputFromUri: (imageUri) => ({
                id: cell.id,
                image_uri: imageUri,
              })
            };

          return (
            <Mutation
              mutation={mutation}
              refetchQueries={() => [{ query: queries.cells.image.get, variables: {id: cell.id} }]}
            >
              {(mutationFct) => (
                <ImageUploader value={imageUriValue} onUpload={(imageUri) => {
                  const data = mutationInputFromUri(imageUri);
                  mutationFct({ variables: { input: data }});
                }} />
              )}
            </Mutation>
          )

        }}
      </Query>
    );
  }
}

export default ImageCellEditor;
