import React from "react"
import '../../style/GamePrizes.scss'
import { FormattedMessage } from "react-intl"
import { Mutation, Query } from "react-apollo"
import queries from "../../queries"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import PeriodeEditor from "./PeriodeEditor"

export default function GamePrizePeriodeEdit(){

    const {prizeId} = useParams();

    console.log(prizeId);

    return(
        <div className="periodes-container">
            <Query query={queries.games.getPrizePeriode} variables={{prize_id: prizeId}}>
                {
                     ({ loading, error, data }) => {
                    if(loading){
                        return 'Loading';
                    }
                    if(error){
                        return error.message;
                    }
                    const periodes = data.prizePeriodes;
                    console.log(periodes);
                    return(
                        <div className="tsk-periodes-container">
                            <Mutation mutation={queries.games.createPeriode} refetchQueries={{}}>
                                 {
                                       (createPeriode)=>{
                                           return(
                                             <button className="tsk-button" onClick={()=>{createPeriode({variables:{prize_id: prizeId}})}}>
                                                <FormattedMessage id="Create new periode"/>
                                             </button>
                                            )
                                        }
                                    }

                                    </Mutation>

                                <div>
                            </div>
                            {
                                periodes.map((periode)=>{
                                    return(
                                        <PeriodeEditor key={periode.id} periode={periode}/>
                                    )
                                })
                            }
                        </div>
                        )
                     }               
                }
            </Query>
        </div>
    )
}