import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {Mutation, Query} from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../style/ProjectEdition.scss';
import {FormattedMessage} from "react-intl";
import PageEdition from "../pages/PageEdition";
import queries from "../../queries";
import NeedAdmin from "../NeedAdmin";
import ScreenTypeSelector from "../ScreenTypeSelector";
import confirmDialog from "../dialogs/ConfirmDialog";

class ProjectEdition extends React.Component {

  deleteDialogConfirmationContent = (
    <FormattedMessage id={'page.delete.confirmation.dialog.message'} values={{br: <br/>}} />
  );

  render() {
    const { projectId, pageId, cellId } = this.props.match.params;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { project } = data;

          document.fonts.clear();
          for (const font of project.fonts) {
            const fontFace = new FontFace(font.name, `url(${font.uri})`);
            fontFace.load().then((loadedFont) => {
              document.fonts.add(loadedFont);
            }).catch(function(error) {
              // error occurred
            });
          }

          return (
            <div className='tsk-project-edit-container'>
              <div className='tsk-project-structure'>
                <h4>
                  <FormattedMessage id='project.structure' />
                </h4>
                <ul>
                  {project.pages.map((page) => {
                    const isCurrent = page.id === pageId;
                    return (
                      <li key={page.id} className={`tsk-page-list-item ${isCurrent ? 'tsk-current': ''}`}>
                        <FontAwesomeIcon icon={[isCurrent ? 'fas':'far', 'file']} className={'tsk-bullet-point'} />
                        <Link to={`/project/${projectId}/page/${page.id}`}>
                          {page.name}
                        </Link>
                        <NeedAdmin>
                          {page.id !== project.main_page_id &&
                          <Mutation
                            mutation={queries.projects.update}
                            refetchQueries={() => [
                              { query: queries.projects.get, variables: {id: projectId} },
                              { query: queries.projects.getWithChildren, variables: {id: projectId} }
                            ]}
                          >
                            {(updateProject) => (
                              <button type={'button'} className={'tsk-button tsk-link tsk-main-page-button'} onClick={() => {
                                updateProject({ variables:{ input: {
                                  main_page_id: page.id,
                                  id: projectId
                                }}});
                              }}>
                                &nbsp;<FontAwesomeIcon icon={['fas', 'home']}/>
                              </button>
                            )}
                          </Mutation>
                          }
                        </NeedAdmin>
                        {page.id === project.main_page_id &&
                          <span>
                            &nbsp;<FontAwesomeIcon icon={['fas', 'home']}/>
                          </span>
                        }
                        <NeedAdmin>
                          <Mutation
                            mutation={queries.pages.duplicate}
                            refetchQueries={() => [
                              { query: queries.projects.get, variables: {id: projectId} },
                              { query: queries.projects.getWithChildren, variables: {id: projectId} }
                            ]}
                          >
                            {(duplicatePage) => (
                              <button type={'button'} className={'tsk-button tsk-link tsk-main-page-button'} onClick={() => {
                                duplicatePage({ variables:{ input: page.id}});
                              }}>
                                &nbsp;<FontAwesomeIcon icon={['far', 'copy']}/>
                              </button>
                            )}
                          </Mutation>
                        </NeedAdmin>
                        {page.id !== project.main_page_id &&
                          <NeedAdmin>
                            <Mutation
                              mutation={queries.pages.del}
                              refetchQueries={() => [
                                { query: queries.projects.get, variables: {id: projectId} },
                                { query: queries.projects.getWithChildren, variables: {id: projectId} },
                                { query: queries.tabs.listForProjectId, variables: {project_id: projectId} },
                              ]}
                            >
                              {(deletePage) => (
                                <button type={'button'} className={'tsk-button tsk-link tsk-main-page-button'} onClick={() => {
                                  confirmDialog(this.deleteDialogConfirmationContent).then(res =>
                                    deletePage({ variables:{ input: page.id}}).then(() => {
                                      if(isCurrent) {
                                        this.props.history.push(`/project/${projectId}/page/${project.main_page_id}`);
                                      }
                                    })
                                  );
                                }}>
                                  &nbsp;<FontAwesomeIcon icon={'trash-alt'}/>
                                </button>
                              )}
                            </Mutation>
                          </NeedAdmin>
                        }
                      </li>
                    );
                  })}
                  <NeedAdmin>
                    <li className={'tsk-create-page'}>
                      <FontAwesomeIcon icon={'plus'} className={'tsk-bullet-point'} />
                      <Mutation
                        mutation={queries.pages.create}
                        refetchQueries={[{query:queries.projects.getWithChildren, variables:{ id: projectId }}]}
                        onCompleted={({createPage}) => {
                          this.props.history.push(`/project/${projectId}/page/${createPage.id}`);
                        }}
                      >
                        {(createPage) =>
                          (
                            <button type={'button'} className={'tsk-button tsk-link'} onClick={() => createPage({ variables: { input: {project_id: projectId }} })}>
                              <FormattedMessage id={'project.page.add'} />
                            </button>
                          )
                        }
                      </Mutation>
                    </li>
                  </NeedAdmin>
                </ul>
              </div>
              <div className='tsk-project-content'>
                {pageId && (
                  <Fragment>
                    <ScreenTypeSelector>
                      { (screenType) =>
                        <PageEdition screenType={screenType} fontRatio={project.default_font_ratio} />
                      }
                    </ScreenTypeSelector>
                  </Fragment>
                )}
              </div>
            </div>
          );
        }}
      </Query>


    );
  }
}

export default withRouter(ProjectEdition);
