import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateIframeCell ($input: UpdateIframeCellInput!) {
      updateIframeCell (input: $input) {
        id
        cell_type
        uri
        i18n {
          language_code
          uri
        }
      }
    }`,

  get: gql`
    query IframeCell($id: ID!) {
      iframeCell(id: $id) {
        id
        uri
        i18n {
          language_code
          uri
        }
      }
    }`
}
