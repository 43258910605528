import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import * as _ from "lodash";
import {ellipsis} from "../../../utils";

const areContentEquals = (list1, list2) => {
  if(list1.length !== list2.length) return false;
  for(const e1 of list1) {
    const e2 = list2.find(e => e.id === e1.id);
    if (!e2) return false;
    if (!_.isEqual(e1, e2)) return false;
  }
  return true;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ProductsSortOrder extends React.PureComponent {
  state = {};

  componentDidMount() {
    this.updateListFromProps(this.props.products);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateListFromProps(this.props.products);
  }

  updateListFromProps(newProductsList) {
    const { products } = this.state;
    if (!products || !areContentEquals(products, newProductsList)) {
      this.setState({products: newProductsList});
    }
  }

  async onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const products = reorder(
      this.state.products,
      result.source.index,
      result.destination.index
    );

    await this.props.onOrderChanged(products);
    this.setState({ products });
  }

  render() {
    const { products } = this.state;

    if(!products) return null;

    return (
      <DragDropContext onDragEnd={async (e) => this.onDragEnd(e)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ol
              className='tsk-product-list-container'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {products.map((product, idx) => {
                return (
                  <Draggable key={product.id} draggableId={product.id} index={idx}>
                    {(provided, snapshot) => (
                      <li
                        className='tsk-product-list-item'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span>{product.name}</span>
                        {product.short_description &&
                          <span>&nbsp;({ellipsis(product.short_description, 20)})</span>
                        }
                      </li>
                    )}
                  </Draggable>
                )
              })}
            </ol>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

}

export default ProductsSortOrder;
