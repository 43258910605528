import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateProductsListCell ($input: UpdateProductsListCellInput!) {
      updateProductsListCell (input: $input) {
        id
        text_color
        background_color
        modal_background_color
        modal_text_color
        modal_backdrop_color
        font
      }
    }`,

  get: gql`
    query ProductsListCell($id: ID!) {
      productsListCell(id: $id) {
        id
        text_color
        background_color
        modal_background_color
        modal_text_color
        modal_backdrop_color
        font
        products {
          id
          name
          short_description
          price
          small_image_uri
          i18n {
            language_code
            name
            price
            short_description
            small_image_uri
          }
        }
        categories_filter {
            id
            name
        }
      }
    }`
}
