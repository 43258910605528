import React, { useState } from "react"
import queries from "../../queries";
import { Mutation, Query } from "react-apollo";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";

export default function DisplayGroupsList(){

    const [groupName, setGroupName] = useState("");

    return(
      <Mutation
        mutation={queries.displays.createDevicesGroup}
        refetchQueries={[{query: queries.displays.listGroup}]}
      >
        {
          (createDevicesGroup)=>{
            return(
            <div className="tsk-container-display-edit">
              <input onChange={(e)=>{setGroupName(e.target.value)}} type="text" maxLength={255} minLength={3} className="tsk-input-lines"/>
              <button className="tsk-btn-update" onClick={()=>{createDevicesGroup({variables: {name: groupName}})}}>
                <FormattedMessage id="display.create.group"/>
              </button>
              <Query query={queries.displays.listGroup}>
              {({ loading, error, data }) => {
              if (loading) return 'Loading...';
              if (error) return `Error! ${error.message}`;

              console.log(data);
              if(data.devicesGroups.length === 0){
                return null;
              }

              return (
                <div>
                 <ul className='tsk-projects-list-container'>
                 <li className="tsk-projects-list-item">
                    <Link to={`/admin/displaysGroup/all`}>
                      All
                    </Link>
                  </li>
                  <li className="tsk-projects-list-item">
                    <Link to={`/admin/displaysGroup/bureau`}>
                      Bureau
                    </Link>
                  </li>
                 {data.devicesGroups.map((group) => {
                  return (
                  <li key={group.id} className='tsk-projects-list-item'>
                    <Link to={`/admin/displaysGroup/${group.id}`}>
                      {group.name}
                    </Link>
                  </li>
                )
              })}
              </ul>
            </div>
          )
        }}
        </Query>
            </div>
            )
          }
        }
      </Mutation>
    )
}
