import React from "react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import queryString from 'query-string';

import {ProjectApp} from "@treeosk/common"

import {backEndUri} from '../../config';


class ProjectPreview extends React.PureComponent {

  state = {};

  componentDidMount() {
    const { projectId } = this.props.match.params;
    this.fetchPage = this.fetchPage.bind(this);
    fetch(`${backEndUri}/project/${projectId}/json?withPages=false`)
      .then(response => response.json())
      .then(data => this.setState({ project: data }));
  }

  ratioFromParams(params) {
    if (!params.ratio) return undefined;
    if (Number(params.ratio)) return Number(params.ratio);
    const split = params.ratio.split('/');
    if (split.length !== 2) throw Error(`Illegal argument: ratio=${params.ratio}`);
    return parseInt(split[0]) / parseInt(split[1]);
  }

  async fetchPage(pageId) {
    const { projectId } = this.props.match.params;
    return fetch(`${backEndUri}/project/${projectId}/page/${pageId}/json`)
      .then(response => response.json())
  }

  uriTransformer = (uri) => uri

  render() {
    const { project } = this.state;
    if(!project) return null;

    const params = queryString.parse(this.props.location.search);
    const width = parseInt(params.width);
    const ratio = this.ratioFromParams(params);
    const routeBaseName = this.props.location.pathname.replace(/^(.+\/preview)\/?(.*)$/, "$1");

    return <ProjectApp
      routeBaseName={routeBaseName}
      project={project}
      fetchPage={this.fetchPage}
      uriTransformer={this.uriTransformer}
      width={width}
      ratio={ratio}
    />

  }
}

ProjectPreview.propTypes = {
  projectId: PropTypes.string
};

export default withRouter(ProjectPreview);
