import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import {FormattedMessage, injectIntl} from "react-intl";
import PaletteColorPicker from "../../PaletteColorPicker";
import {withRouter} from "react-router-dom";
import {stateOrValue} from "../../../utils";
import _ from "lodash";

class CocaFormCellEditor extends React.Component  {

  state = {
    i18n: {}
  };

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  colorPicker = (cocaFormCell, fieldName, palette, mutationFct) => (
    <div className={'tsk-input-line'}>
      <label><FormattedMessage id={`cell.email_form.${fieldName}.label`} /></label>
      <PaletteColorPicker
        color={cocaFormCell[fieldName]}
        disableAlpha={true}
        palette={palette}
        onChange={(color) => {
          const rgb = color.rgb;
          const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
          mutationFct({ variables: { input: { id: cocaFormCell.id, [fieldName]: colorStr } }})
        }}
      />
    </div>
  );

  render() {
    const { projectId } = this.props.match.params;
    const { cell, language } = this.props;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {(projectQueryResponse) => {
          if (projectQueryResponse.loading) return null;
          if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
          const {project} = projectQueryResponse.data;
          if(!project) return null;

          return (
            <Query query={queries.cells.coca_form.get} variables={{ id: cell.id }}>
              {({ loading, error, data }) => {
                if (loading) return null;
                if (error) return `Error!: ${error}`;

                const { cocaFormCell } = data;
                if (!cocaFormCell) return null;

                if(!!language) {
                  const i18nState = this.state.i18n[language.code] || {};
                  const i18nValues = _.filter(cocaFormCell.i18n, {language_code: language.code})[0] || {};
                  return (
                    <Mutation
                      mutation={queries.cells.i18n.update}
                      refetchQueries={() => [{ query: queries.cells.coca_form.get, variables: {id: cell.id} }]}
                    >
                      {(i18nCell) => (
                        <Fragment>
                          <div className={'tsk-input-line'}>
                            <label htmlFor={'submit_button_text'}><FormattedMessage id={'cell.email_form.submit_button_text.label'} /></label>
                            <input name={'submit_button_text'} value={stateOrValue(i18nState.submit_button_text, i18nValues.submit_button_text, {defaultValue: ''})} onChange={(e) => {
                              this.setI18nState(language, i18nState, {submit_button_text: e.target.value})
                            }}/>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'confirmation_text'}><FormattedMessage id={'cell.email_form.confirmation_text.label'} /></label>
                            <input name={'confirmation_text'} value={stateOrValue(i18nState.confirmation_text, i18nValues.confirmation_text, {defaultValue: ''})} onChange={(e) => {
                              this.setI18nState(language, i18nState, {confirmation_text: e.target.value})
                            }}/>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'error_text'}><FormattedMessage id={'cell.email_form.error_text.label'} /></label>
                            <input name={'error_text'} value={stateOrValue(i18nState.error_text, i18nValues.error_text, {defaultValue: ''})} onChange={(e) => {
                              this.setI18nState(language, i18nState, {error_text: e.target.value})
                            }}/>
                          </div>

                          <div className={'tsk-bottom-buttons'}>
                            <button className={'tsk-button'} onClick={() => {
                              const data = {
                                cell_id: cell.id,
                                language_code: language.code,
                                ...(i18nState.submit_button_text !== undefined ? {submit_button_text: i18nState.submit_button_text} : {}),
                                ...(i18nState.confirmation_text !== undefined ? {confirmation_text: i18nState.confirmation_text} : {}),
                                ...(i18nState.error_text !== undefined ? {error_text: i18nState.error_text} : {}),
                              };
                              i18nCell({ variables: { input: data }})
                            }}>
                              <FormattedMessage id={'common.button.submit'} />
                            </button>
                          </div>
                        </Fragment>
                      )}
                    </Mutation>
                  )
                }

                return (
                  <Mutation
                    mutation={queries.cells.coca_form.update}
                    refetchQueries={() => [{ query: queries.cells.coca_form.get, variables: {id: cell.id} }]}
                  >
                    {(mutationFct) => (
                      <Fragment>
                        {this.colorPicker(cocaFormCell, "border_color", project.color_palette, mutationFct)}
                        {this.colorPicker(cocaFormCell, "submit_text_color", project.color_palette, mutationFct)}
                        {this.colorPicker(cocaFormCell, "input_text_color", project.color_palette, mutationFct)}
                        {this.colorPicker(cocaFormCell, "confirmation_text_color", project.color_palette, mutationFct)}
                        {this.colorPicker(cocaFormCell, "error_text_color", project.color_palette, mutationFct)}
                        {this.colorPicker(cocaFormCell, "input_background_color", project.color_palette, mutationFct)}
                        {this.colorPicker(cocaFormCell, "submit_background_color", project.color_palette, mutationFct)}


                        <label htmlFor={'font'}><FormattedMessage id={'cell.text.font.label'} /></label>
                        <select
                          value={stateOrValue(this.state.font, cocaFormCell.font, {defaultValue: project.fonts[0].name})}
                          onChange={(e) => {
                            this.setState({font: e.target.value});
                          }}
                        >
                          {project.fonts.map((font) =>
                            <option key={font.id} value={font.name}>{font.name}</option>
                          )}
                        </select>

                        <div className={'tsk-input-line'}>
                          <label htmlFor={'submit_button_text'}><FormattedMessage id={'cell.email_form.submit_button_text.label'} /></label>
                          <input name={'submit_button_text'} value={stateOrValue(this.state.submit_button_text, cocaFormCell.submit_button_text, {defaultValue: ''})} onChange={(e) => {
                            this.setState({submit_button_text: e.target.value})
                          }}/>
                        </div>

                        <div className={'tsk-input-line'}>
                          <label htmlFor={'confirmation_text'}><FormattedMessage id={'cell.email_form.confirmation_text.label'} /></label>
                          <input name={'confirmation_text'} value={stateOrValue(this.state.confirmation_text, cocaFormCell.confirmation_text, {defaultValue: ''})} onChange={(e) => {
                            this.setState({confirmation_text: e.target.value})
                          }}/>
                        </div>

                        <div className={'tsk-input-line'}>
                          <label htmlFor={'error_text'}><FormattedMessage id={'cell.email_form.error_text.label'} /></label>
                          <input name={'error_text'} value={stateOrValue(this.state.error_text, cocaFormCell.error_text, {defaultValue: ''})} onChange={(e) => {
                            this.setState({error_text: e.target.value})
                          }}/>
                        </div>

                        <div className={'tsk-bottom-buttons'}>
                          <button className={'tsk-button'} onClick={() => {
                            const data = {
                              id: cell.id,
                              ...(this.state.font !== undefined ? {font: this.state.font} : {}),
                              ...(this.state.submit_button_text !== undefined ? {submit_button_text: this.state.submit_button_text} : {}),
                              ...(this.state.confirmation_text !== undefined ? {confirmation_text: this.state.confirmation_text} : {}),
                              ...(this.state.error_text !== undefined ? {error_text: this.state.error_text} : {}),
                            };
                            mutationFct({ variables: { input: data }})
                          }}>
                            <FormattedMessage id={'common.button.submit'} />
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </Mutation>
                )

              }}
            </Query>
          )}}
      </Query>
    );
  }
}

export default withRouter(CocaFormCellEditor);
