import React from 'react';
import { ChromePicker } from 'react-color'

import '../style/ColorPicker.scss'

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    ...(this.props.color ? {color: this.props.color} : {})
  };

  handleClick = () => {
    if(!this.state.displayColorPicker) {
      this.setState({displayColorPicker: true});
    } else {
      this.handleClose();
    }
  };

  handleClose = () => {
    const { color } = this.state;
    const { onChange } = this.props;
    onChange(color);
    this.setState({ displayColorPicker: false })
  };

  render() {
    const { disableAlpha } = this.props;
    const { color } = this.state;

    return (
      <div className={'tsk-colorpicker-container'}>
        <div className={'tsk-colorpicker-block'} onClick={ this.handleClick }>
          <div className={'tsk-color'} style={{background: color}} />
        </div>
        { this.state.displayColorPicker &&
          <div className={'tsk-colorpicker-popover'}>
            <div className={'tsk-colorpicker-cover'} onClick={ this.handleClose }/>
            <ChromePicker color={ this.state.color } onChange={ (newValue) => {
              const rgb = newValue.rgb;
              this.setState({ color: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
            } } disableAlpha={disableAlpha} />
          </div>
        }
      </div>
    )
  }
}

export default ColorPicker
