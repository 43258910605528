import React from 'react';

import FileUploader from "./FileUploader";
import confirmDialog from "../dialogs/ConfirmDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage} from "react-intl";

class ImageUploader extends React.Component {

  render() {
    const {maxSize, value, onUpload, onDelete, canDelete = false} = this.props;

    const deleteDialogConfirmationContent = (
      <FormattedMessage id={'image.delete.confirmation.dialog.message'} values={{br: <br/>}} />
    );

    return (
      <div className={'tsk-imagecell-preview'}>
        {value &&
          <div style={{position: 'relative'}}>
            <img alt="Cell content" src={value} />
            {canDelete &&
            <span
              className={'tsk-delete-icon tsk-image'}
              onClick={async (e) => {
                e.preventDefault();
                await confirmDialog(deleteDialogConfirmationContent).then(
                  async (result) => {
                    console.log('deleting image');
                    //TODO actually delete the image file?
                    await onDelete();
                  }
                );
              }}
            >
              <FontAwesomeIcon icon={'trash-alt'}/>
            </span>
            }
          </div>
        }
        <FileUploader
          accept={"image/*"}
          onUpload={onUpload}
          maxSize={maxSize}
          uploadPath={"/image"}
        />
      </div>
    )
  }
}

export default ImageUploader;
