import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateCarouselCell ($input: UpdateCarouselCellInput!) {
      updateCarouselCell (input: $input) {
        id
        cell_type
        background_type
        background_image_uri
        background_video_uri
        background_color
        padding
        arrows
        arrows_inner_color
        arrows_outer_color
      }
    }`,

  get: gql`
    query CarouselCell($id: ID!, $screen_type_id: ID!) {
      carouselCell(id: $id) {
        id
        cell_type
        coordinates(screen_type_id: $screen_type_id) {
          height
        }
        background_type
        background_image_uri
        background_video_uri
        background_color
        padding
        arrows
        arrows_inner_color
        arrows_outer_color
        slides {
          id
          image_uri
          to_page_id
          language_code
        }
      }
    }`,

  updateSlide:gql`
    mutation UpdateCarouselSlide ($input: UpdateCarouselSlideInput!) {
      updateCarouselSlide (input: $input) {
        id
        image_uri
        to_page_id
        language_code
      }
    }`,

  addSlide: gql`
    mutation AddCarouselSlide ($input: AddCarouselSlideInput!) {
      addCarouselSlide (input: $input) {
        id
        image_uri
        to_page_id
        language_code
      }
    }`,

  deleteSlide:gql`
    mutation DeleteCarouselSlide ($input: ID!) {
      deleteCarouselSlide (input: $input) {
        id
        image_uri
        to_page_id
        language_code
      }
    }`,

}
