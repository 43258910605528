import React, {Component} from "react";
import { FormattedMessage } from "react-intl";
import NeedAdmin from "../NeedAdmin";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Query } from "react-apollo";
import queries from "../../queries";

class GamePrizes extends Component{

    constructor(props){
        super(props);
        this.state = {project_id : '', name: '', filterText: '', location: ''};
    }

    render(){
        const { projectId } = this.props.match.params;
        return(
            <NeedAdmin trueAdmin>
            <ul className='tsk-dispays-list-container'>
            <li className='tsk-displays-row'>
            <Link className={"tsk-displays-list-item"}>
            <div className='tsk-display-col-item bold'><FormattedMessage id="games.list.colone.game_type"></FormattedMessage></div>
            <div className='tsk-display-col-item bold'><FormattedMessage id="games.list.colone.game_participations"></FormattedMessage></div>
            <div className='tsk-display-col-item bold'><FormattedMessage id="games.list.colone.default_label"></FormattedMessage></div>
            </Link>
            </li>
            <Query query={queries.games.getGameProjectId} variables={{ project_id: projectId }}>
            {({loading, error, data})=>{
                if(loading){
                    return 'loading';
                }if(error){
                    return error.message
                }else{

                    return data.gamesForProjectId.map((game)=>{
                        return(
                            <li className='tsk-displays-row'>
                            <Link to={'/project/'+projectId+'/gameprizes/' + game.id} className={"tsk-displays-list-item"}>
                            <div className='tsk-display-col-item'>{game.game_type}</div>
                            <div className='tsk-display-col-item'>{game.estimated_nb_players}</div>
                            <div className='tsk-display-col-item'>{(game.default_prize && game.default_prize.label) ? game.default_prize.label : 'none' }</div>
                            </Link>
                            </li>
                            )
                        });
                    }
                }
            }
            </Query>
            </ul>
            </NeedAdmin>
            );
            }
        }

    export default GamePrizes;
