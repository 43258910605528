import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateCocaFormCell ($input: UpdateCocaFormCellInput!) {
      updateCocaFormCell (input: $input) {
        id
        cell_type
        submit_button_text
        confirmation_text
        error_text
        border_color
        submit_text_color
        input_text_color
        confirmation_text_color
        error_text_color
        input_background_color
        submit_background_color
        font
        i18n {
          language_code
          submit_button_text
          confirmation_text
          error_text
        }
      }
    }`,

  get: gql`
    query CocaFormCell($id: ID!) {
      cocaFormCell(id: $id) {
        id
        submit_button_text
        confirmation_text
        error_text
        border_color
        submit_text_color
        input_text_color
        confirmation_text_color
        error_text_color
        input_background_color
        submit_background_color
        font
        i18n {
          language_code
          submit_button_text
          confirmation_text
          error_text
        }
      }
    }`
}
