import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link, Route, Switch} from 'react-router-dom';

import '../../style/Topbar.scss';
import {FormattedMessage, injectIntl} from "react-intl";
import {Mutation, Query} from "react-apollo";
import queries from "../../queries";
import {backEndUri} from '../../config';
import NeedAuth from "../NeedAuth";
import confirmDialog from "../dialogs/ConfirmDialog";
import NeedAdmin from "../NeedAdmin";
import { apolloClient } from '../../config';


class Topbar extends Component {

  state = {confirmShow: false}

  publishDialogConfirmationContent = (
    <FormattedMessage id={'project.publish.dialog.message'} values={{br: <br/>}} />
  );

  render() {
    const {identityProvider, intl} = this.props;
    return (
      <div className='tsk-topbar'>
        <NeedAuth identityProvider={identityProvider} showLoginButton={false}>
          <div className='tsk-topbar-title'>
            <Switch>
              <Route exact path='/project' render={ () =>
                <span>
                  <FormattedMessage id='projects.title'/>
                </span>
              } />
              <Route exact path='/project/new' render={ () =>
                <span>
                  <FormattedMessage id='projects.new.title'/>
                </span>
              } />
              <Route path='/project/:projectId' render={ ({match}) => {
                const { projectId } = match.params;
                return <Query query={queries.projects.get} variables={{ id: projectId }}>
                  {({ loading, error, data }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;

                    const { project } = data;
                    return (
                      <span>{project.name}</span>
                    )
                  }}
                </Query>
              }} />
              <Route path='/admin/displaysGroup/:groupId' render={({match})=>{
                const {groupId} = match.params;
                return (groupId !== "bureau" && groupId !== "all") ? (
                <Query query={queries.displays.listByGroup} variables={{id: groupId}}>
                  {({loading, error, data})=>{
                    if(loading){
                      return null;
                    }
                    if(error){
                      return error.message;
                    }
                    const group = data.devicesGroup;
                    return(
                      <>
                      <span>
                        {group.name}
                      </span>
                      <Mutation mutation={queries.displays.deleteGroup}>
                       {(remove)=>{
                           return(
                            <button
                              className={'tsk-button tsk-warning'}
                              onClick={() => {
                              confirmDialog('Delete this group now').then(res => {
                            apolloClient.mutate({
                            mutation: queries.displays.deleteGroup,
                            variables: {id: groupId},
                            refetchQueries: [{query: queries.displays.listGroup}]
                          }).then((res) => {
                            if(!res.errors && res.data) {
                              window.location.href  = '/admin/displays';
                            }
                          });
                        });
                      }}>
                        <FormattedMessage id={'common.button.delete'} />
                        </button>
                           )
                         }}
                      </Mutation>
                       </>
                    )
                  }}
                </Query>
                )
                :
                <span>
                  {groupId}
                </span>
              }}/>
            </Switch>
          </div>
          <div className='tsk-topbar-content'>
            <Switch>
              <Route exact path='/project' render={ () =>
                <NeedAdmin trueAdmin>
                  <div />
                  <Link className='tsk-link tsk-upper' to={'/project/new'}>
                    <FormattedMessage id='projects.create.button' />
                  </Link>
                </NeedAdmin>
              } />
              <Route path='/project/new' />
              <Route path='/project/:projectId/tabMenu' render={ ({match}) =>
                <div>
                  <FormattedMessage id='project.tabmenus.title' />
                </div>
              } />
              <Route path='/project/:projectId' render={ ({match}) => {
                const { projectId } = match.params;
                return <Query query={queries.projects.get} variables={{ id: projectId }}>
                  {({ loading, error, data }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;

                    const { project } = data;
                    return (
                      <React.Fragment>
                        <div className={'tsk-grow'} />
                        <NeedAdmin trueAdmin>
                          <a className={'tsk-link tsk-upper'} href={`${backEndUri}/project/${projectId}/stats`} download>
                            <FormattedMessage id='projects.stats.button' />
                          </a>
                        </NeedAdmin>
                        <button className={'tsk-button tsk-link tsk-upper'} onClick={() => {
                          confirmDialog(this.publishDialogConfirmationContent).then(res => {
                            const publishUri = `${backEndUri}/project/${projectId}/publish`;
                            fetch(publishUri)
                              .then(response =>response.json())
                              .then(jsResponse => {
                                //TODO display a small confirmation message
                                console.log(JSON.stringify(jsResponse));
                              });
                          });
                        }}>
                          <FormattedMessage id='projects.publish.button' />
                        </button>
                        <NeedAdmin trueAdmin>
                          {project.latest_version && (
                            <a className={'tsk-link tsk-upper'} href={project.latest_version.uri} download>
                              <FormattedMessage id='projects.download.button' />
                            </a>
                          )}
                          {!project.latest_version && (
                            <button className={'tsk-button tsk-link tsk-upper'} onClick={() => {
                              const alertMessage = intl.formatMessage({id: 'project.download.nonpublished.dialog.message'});
                              alert(alertMessage);
                            }}>
                              <FormattedMessage id='projects.download.button' />
                            </button>
                          )}
                        </NeedAdmin>
                        <button className={'tsk-button tsk-link tsk-upper'} onClick={() => {
                          window.open(`/project/${projectId}/preview`, 'treeosk-preview', 'height=640,location=no,menubar=no,resizable=no,scrollbars=yes,status=yes,titlebar=no,toolbar=no,width=360');
                        }}>
                          <FormattedMessage id='projects.preview.button' />
                        </button>
                      </React.Fragment>
                    )
                  }}
                </Query>
              }} />
            </Switch>
          </div>
        </NeedAuth>
      </div>
    );
  }
}

Topbar.propTypes = {
  identityProvider: PropTypes.object.isRequired,
};

export default injectIntl(Topbar);
