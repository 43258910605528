import React from 'react';
import { useCalendarApp, ScheduleXCalendar } from '@schedule-x/react';
import { viewWeek, viewDay, viewMonthGrid, viewMonthAgenda } from '@schedule-x/calendar';
 
import '@schedule-x/theme-default/dist/index.css'
 
function CalendarApp({events}) {
  const calendar = useCalendarApp({
    defaultView: viewMonthGrid.name,
    views: [viewDay, viewWeek, viewMonthGrid, viewMonthAgenda],
    events,
  })
 
  return (
    <div style={{width: "100%", marginTop: '40px', marginBottom: '40px'}}>
      <ScheduleXCalendar calendarApp={calendar} />
    </div>
  )
}
 
export default CalendarApp