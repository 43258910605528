import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {VideoCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class VideoCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={'film'} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.video.get}
        renderingCondition={(videoCell) => !!videoCell.video_uri}
      >
        {(videoCell) => <VideoCell preview={true} cell={videoCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default VideoCellPreview;
