import React, {Fragment} from 'react';
import RichTextEditor from 'react-rte';
import _ from 'lodash';

import {Mutation, Query} from "react-apollo";
import {stateOrValue} from "../../../utils"; 
import queries from "../../../queries";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import BackgroundSelector from "../../BackgroundSelector";
import RichTextContentEditor from "./RichTextContentEditor";
import ImageUploader from "../../uploaders/ImageUploader";
import VideoUploader from "../../uploaders/VideoUploader";
import NeedAdmin from "../../NeedAdmin";

class TextCellEditor extends React.Component {

  state = {
    i18n: {}
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.cell, this.props.cell)) {
      const emptyState = _.mapValues(this.state, () => undefined);
      console.log('emptyState=', emptyState);
      this.setState({...emptyState, i18n: {}});
    }
  }

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  render() {
    const {cell, intl, language} = this.props;
    const { projectId } = this.props.match.params;

    if (!cell) return null;

    return (
      <Query query={queries.cells.text.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const {textCell} = data;
          if (!textCell) return null;

          return (
            <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
              {(projectQueryResponse) => {
                if (projectQueryResponse.loading) return null;
                if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
                const {project} = projectQueryResponse.data;
                if (!project) return null;

                const letterSpacing = stateOrValue(this.state.letterSpacing, textCell.letter_spacing, {defaultValue: 0});
                const lineHeight = stateOrValue(this.state.lineHeight, textCell.line_height, {defaultValue: 1});

                const spacingStyle = {
                  ...(letterSpacing !== undefined ? {'--tsk-txtcelleditor-letterspacing': `${letterSpacing}em`} : {}),
                  ...(lineHeight !== undefined ? {'--tsk-txtcelleditor-lineheight': `${lineHeight}em`} : {})
                };

                if(!!language) {
                  const i18nState = this.state.i18n[language.code] || {};
                  const i18nValues = _.filter(textCell.i18n, {language_code: language.code})[0] || {};

                  const value = (i18nState.text_data !== undefined) ?
                    i18nState.text_data : (
                      !!i18nValues.text_data ?
                        RichTextEditor.createValueFromString(JSON.stringify(i18nValues.text_data), 'raw') :
                        RichTextEditor.createEmptyValue()
                    );

                  return (
                    <Mutation
                      mutation={queries.cells.i18n.update}
                      refetchQueries={() => [{ query: queries.cells.button.get, variables: {id: cell.id} }]}
                    >
                      {(i18nCell) => (
                        <div>
                          <RichTextContentEditor
                            spacingStyle={spacingStyle}
                            value={value}
                            onChange={(value) => this.setI18nState(language, i18nState, {text_data: value})}
                            project={project}
                          />

                          <NeedAdmin>
                          {stateOrValue(this.state.backgroundType, textCell.background_type, {transform: (val) => val === 'image'}) &&
                          <Fragment>
                            <label>
                              <FormattedMessage id={'cell.text.background.section.label'} />
                              &nbsp;
                              <FormattedMessage id={'cell.text.background.image.label'} />
                            </label>
                            <div className={'tsk-input-line'}>
                              <ImageUploader
                                value={stateOrValue(i18nState.background_image_uri, i18nValues.background_image_uri)}
                                onUpload={(imageUri) => {
                                  this.setI18nState(language, i18nState, {background_image_uri: imageUri})
                                }}
                              />
                            </div>
                          </Fragment>
                          }

                          {stateOrValue(this.state.backgroundType, textCell.background_type, {transform: (val) => val === 'video'}) &&
                          <Fragment>
                            <label>
                              <FormattedMessage id={'cell.text.background.section.label'} />
                              &nbsp;
                              <FormattedMessage id={'cell.text.background.video.label'} />
                            </label>
                            <div className={'tsk-input-line'}>
                              <VideoUploader
                                value={stateOrValue(i18nState.background_video_uri, i18nValues.background_video_uri)}
                                onUpload={(videoUri) => {
                                  this.setI18nState(language, i18nState, {background_video_uri: videoUri})
                                }}
                              />
                            </div>
                          </Fragment>
                          }
                          </NeedAdmin>

                          <div className={'tsk-bottom-buttons'}>
                            <button
                              className={'tsk-button'}
                              onClick={() => {

                              const data = {
                                cell_id: cell.id,
                                language_code: language.code,
                                ...(i18nState.text_data !== undefined ? {text_data: i18nState.text_data.toString('raw')} : {}),
                                ...(i18nState.background_image_uri !== undefined ? {background_image_uri: i18nState.background_image_uri} : {}),
                                ...(i18nState.background_video_uri !== undefined ? {background_video_uri: i18nState.background_video_uri} : {}),
                              };
                              i18nCell({ variables: { input: data }})
                            }}>
                              <FormattedMessage id={'common.button.submit'} />
                            </button>
                          </div>
                        </div>
                      )}
                    </Mutation>
                  )
                }


                const value = this.state.value ?
                  this.state.value : (
                    textCell.text_data ?
                      RichTextEditor.createValueFromString(JSON.stringify(textCell.text_data), 'raw') :
                      RichTextEditor.createEmptyValue()
                  );

                return (
                  <Mutation
                    mutation={queries.cells.text.update}
                    refetchQueries={() => [{ query: queries.cells.text.get, variables: {id: cell.id} }]}
                  >
                    {(updateTextCell) => (
                      <div>
                        <RichTextContentEditor
                          spacingStyle={spacingStyle}
                          value={value}
                          onChange={(value) => this.setState({value})}
                          project={project}
                        />

                        <NeedAdmin>
                          <div className={'tsk-input-line'}>
                            <label htmlFor={'letterSpacing'}><FormattedMessage
                              id={'cell.text.letterspacing.label'}/></label>
                            <input name={'letterSpacing'}
                                   type={'range'}
                                   min={-0.1}
                                   max={0.5}
                                   step={0.01}
                                   value={letterSpacing}
                                   onChange={(e) => this.setState({letterSpacing: e.target.value})}
                            />
                            <span>{letterSpacing}</span>
                            <button className={'tsk-button tsk-small tsk-upper'} onClick={() => {
                              this.setState({letterSpacing: 0})
                            }}>
                              <FormattedMessage id={'cell.text.reset.label'}/>
                            </button>
                          </div>
                          <div className={'tsk-input-line'}>
                            <label htmlFor={'lineHeight'}><FormattedMessage id={'cell.text.lineheight.label'}/></label>
                            <input name={'lineHeight'}
                                   type={'range'}
                                   min={0}
                                   max={3}
                                   step={0.1}
                                   value={lineHeight}
                                   onChange={(e) => this.setState({lineHeight: e.target.value})}
                            />
                            <span>{lineHeight}</span>
                            <button className={'tsk-button tsk-small tsk-upper'} onClick={() => {
                              this.setState({lineHeight: 1})
                            }}>
                              <FormattedMessage id={'cell.text.reset.label'}/>
                            </button>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'horizontalAlign'}><FormattedMessage id={'cell.text.horizontalalign.label'}/></label>
                            <select
                              value={stateOrValue(this.state.horizontalAlign, textCell.horizontal_align, {defaultValue: ''})}
                              onChange={(e) => {
                                this.setState({horizontalAlign: e.target.value});
                              }}
                            >
                              <option value={'left'}>{intl.formatMessage({id: 'cell.text.horizontalalign.left'})}</option>
                              <option
                                value={'center'}>{intl.formatMessage({id: 'cell.text.horizontalalign.center'})}</option>
                              <option
                                value={'right'}>{intl.formatMessage({id: 'cell.text.horizontalalign.right'})}</option>
                              <option
                                value={'justify'}>{intl.formatMessage({id: 'cell.text.horizontalalign.justify'})}</option>
                            </select>
                          </div>

                          <div className={'tsk-input-line'}>
                            <label htmlFor={'verticalAlign'}><FormattedMessage
                              id={'cell.text.verticalalign.label'}/></label>
                            <select
                              value={stateOrValue(this.state.verticalAlign, textCell.vertical_align, {defaultValue: ''})}
                              onChange={(e) => {
                                this.setState({verticalAlign: e.target.value});
                              }}
                            >
                              <option value={'top'}>{intl.formatMessage({id: 'cell.text.verticalalign.top'})}</option>
                              <option
                                value={'middle'}>{intl.formatMessage({id: 'cell.text.verticalalign.middle'})}</option>
                              <option
                                value={'bottom'}>{intl.formatMessage({id: 'cell.text.verticalalign.bottom'})}</option>
                            </select>
                          </div>

                          <BackgroundSelector
                            value={textCell}
                            palette={project.color_palette}
                            onChange={(bgValues) => this.setState(bgValues)}
                          />
                        </NeedAdmin>

                        <div className={'tsk-bottom-buttons'}>
                          <button
                            className={'tsk-button'}
                            onClick={() => {

                            const data = {
                              id: cell.id,
                              text_data: value.toString('raw'),
                              line_height: parseFloat(lineHeight),
                              letter_spacing: parseFloat(letterSpacing),
                              ...(this.getBackgroundData()),
                              ...(this.state.horizontalAlign ? {horizontal_align: this.state.horizontalAlign} : {}),
                              ...(this.state.verticalAlign ? {vertical_align: this.state.verticalAlign} : {}),
                            };
                            updateTextCell({variables: {input: data}})
                          }}>
                            <FormattedMessage id={'common.button.submit'}/>
                          </button>
                        </div>
                      </div>
                    )}
                  </Mutation>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }

  getBackgroundData() {
    const {backgroundType, backgroundColor, backgroundImageUri, backgroundVideoUri} = this.state;
    if(!backgroundType) return {};
    return {
      background_type: backgroundType,
      background_image_uri: backgroundImageUri,
      background_video_uri: backgroundVideoUri,
      background_color: backgroundColor,
    };
  }
}

export default withRouter(injectIntl(TextCellEditor));
