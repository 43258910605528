import React from 'react';
import PropTypes from 'prop-types';
import { createConfirmation, confirmable } from 'react-confirm';
import {FormattedMessage} from "react-intl";
import WithLang from "../WithLang";
import FileUploader from "../uploaders/FileUploader";

class TextInputDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    options: PropTypes.object,       // arguments of your confirm function
  };

  state={
    textValue: ''
  };


  render() {
    const {proceed, show, cancel, options} = this.props;
    const {textValue} = this.state;
    return (
      <WithLang>
        <div className={'tsk-dialog-container'} style={ show ? {} : {display : 'none'}}>
          <div className={'tsk-dialog'}>
            <div className={'tsk-dialog-content'}>
              {!!options.additionalChildren && options.additionalChildren}
              <div className={'tsk-input-line'}>
                <label htmlFor={'txt-input'}><FormattedMessage id={options.labelId} />&nbsp;*</label>
                <input type={"text"} name={'txt-input'} value={textValue}
                       onChange={(e) => this.setState({textValue: e.target.value})}
                />
              </div>
            </div>
            <div className={'tsk-dialog-buttons'}>
              <button className={'tsk-button'} onClick={() => cancel()}>
                <FormattedMessage id={'dialog.cancel.button'} />
              </button>
              <button className={'tsk-button'} style={{marginLeft: '1em'}} onClick={() => proceed(textValue)}
                      disabled={!textValue || textValue.length === 0}>
                <FormattedMessage id={'dialog.ok.button'} />
              </button>
            </div>
          </div>
        </div>
      </WithLang>
    )
  }
}

// create confirm function
const textInputDialog = createConfirmation(confirmable(TextInputDialog));

export default (options = {}) => {
  return textInputDialog({ options });
}
