import React, {Fragment} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import ColorPicker from "../ColorPicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fileUploadDialog from "../dialogs/FileUploadDialog";
import GlobalButtonSettings from "../cells/editors/GlobalButtonSettings";
import {Mutation, Query} from "react-apollo";
import queries from "../../queries";
import Select from "react-select";
import PaletteColorPicker from "../PaletteColorPicker";
import ImageUploader from "../uploaders/ImageUploader";

class BaseProjectSettings extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.fontNameInputRef = React.createRef();
    this.fontNameErrorRef = React.createRef();
  }

  render() {
    const { project, onNewColor, onColorUpdate, onNewFont, onNewButtonsSettings, onLanguagesUpdate, onNewPrimaryLanguage, onLanguageSelectorSettingsUpdate, onNameChange, intl } = this.props;
    console.log('project=', project);
    const circleLanguageSelectorShapeTxt = intl.formatMessage({id: 'project.settings.languageselector.shape.circle.label'});
    const rectangleLanguageSelectorShapeTxt = intl.formatMessage({id: 'project.settings.languageselector.shape.rectangle.label'});
    const flagLanguageSelectorDisplayTypeTxt = intl.formatMessage({id: 'project.settings.languageselector.displaytype.flag.label'});
    const countryCodeLanguageSelectorDisplayTypeTxt = intl.formatMessage({id: 'project.settings.languageselector.displaytype.countrycode.label'});

    return (
      <div>
        <div className={'tsk-settings-block'}>
          <label><FormattedMessage id={'project.settings.colors.label'} /></label>
          <ul className={'tsk-setting-list'}>
            {project.color_palette.map((color, idx) => (
              <li key={idx}>{color} <ColorPicker disableAlpha={false} color={color} onChange={(color) => onColorUpdate(color, idx)} /></li>
            ))}
            <li className={'tsk-setting-list-new'}>
              <button className={'tsk-button tsk-link'} onClick={onNewColor}>
                <FontAwesomeIcon className={'tsk-bullet-point'} icon={'plus'} />
                <FormattedMessage id={'project.settings.colors.new'} />
              </button>
            </li>
          </ul>
        </div>
        <div className={'tsk-settings-block'}>
          <label htmlFor={'name'}>
            <FormattedMessage id={'project.creation.name'} />
          </label>
          <input name={'name'}
                 value={this.state.projectName || project.name}
                 onChange={(e) => {
                   const projectName = e.target.value;
                   this.setState({ projectName });
                   onNameChange(projectName);
                 }}
          />
        </div>
        <div className={'tsk-settings-block'}>
          <label><FormattedMessage id={'project.settings.languages.label'} /></label>
          <Query query={queries.languages.list}>
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return `Error!: ${error}`;

              const {languages} = data;
              if (!languages) return null;

              return (
                <Fragment>
                  <div className={'tsk-input-line'}>
                    <Select
                      isClearable={false}
                      className={'tsk-long-select'}
                      isMulti={true}
                      getOptionLabel={(language) => language.name}
                      getOptionValue={(language) => language.code}
                      value={project.languages}
                      onChange={onLanguagesUpdate}
                      options={languages}
                      styles={{
                        multiValueRemove: (base, state) => {
                          const selectedValues = state.getValue();
                          return selectedValues.length < 2 ? { ...base, display: 'none' } : base;
                        }
                      }}
                    />
                  </div>

                  {!!project.languages && project.languages.length > 0 &&
                    <Fragment>
                      <div className={'tsk-input-line'}>
                        <label htmlFor={'primaryLanguage'}><FormattedMessage id={'project.settings.languages.primary.label'} /></label>
                        <select
                          value={project.primary_language_code}
                          onChange={(e) => {
                            onNewPrimaryLanguage(e.target.value);
                          }}
                        >
                          {project.languages.map((language) => (
                            <option key={language.code} value={language.code}>{language.name}</option>
                          ))}
                        </select>
                      </div>
                      <Mutation
                        mutation={queries.languages.updateFlag}
                        refetchQueries={() => [{ query: queries.projects.getWithChildren, variables: {id: project.id} }]}
                      >
                      {(updateFlag) => (
                        project.languages.map((language) => (
                          <div className={'tsk-input-line'} key={language.code}>
                            <label><FormattedMessage id={'project.settings.languages.flag.for'} />{language.name}</label>
                            <ImageUploader value={language.flag_image_uri} onUpload={(imageUri) => {
                              updateFlag({ variables: { input: {
                                project_id: project.id, language_code: language.code, flag_image_uri: imageUri
                              }}});
                            }} />
                          </div>
                        ))
                      )}
                      </Mutation>
                    </Fragment>
                  }
                </Fragment>
              )
            }}
          </Query>
        </div>
        {(!!project.languages && project.languages.length > 1) &&
        <div className={'tsk-settings-block'}>
          <label><FormattedMessage id={'project.settings.languageselector.section.label'} /></label>

          <div className={'tsk-input-line'}>
            <label><FormattedMessage id={'project.settings.languageselector.backgroundcolor.label'} /></label>
            <PaletteColorPicker
              color={project.language_selector_background_color}
              disableAlpha={true}
              palette={project.color_palette}
              onChange={(color) => {
                const rgb = color.rgb;
                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                onLanguageSelectorSettingsUpdate({language_selector_background_color: colorStr})
              }}
            />
          </div>

          <div className={'tsk-input-line'}>
            <label><FormattedMessage id={'project.settings.languageselector.bordercolor.label'} /></label>
            <PaletteColorPicker
              color={project.language_selector_border_color}
              disableAlpha={true}
              palette={project.color_palette}
              onChange={(color) => {
                const rgb = color.rgb;
                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                onLanguageSelectorSettingsUpdate({language_selector_border_color: colorStr})
              }}
            />
          </div>

          <div className={'tsk-input-line'}>
            <label><FormattedMessage id={'project.settings.languageselector.textcolor.label'} /></label>
            <PaletteColorPicker
              color={project.language_selector_text_color}
              disableAlpha={true}
              palette={project.color_palette}
              onChange={(color) => {
                const rgb = color.rgb;
                const colorStr = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
                onLanguageSelectorSettingsUpdate({language_selector_text_color: colorStr})
              }}
            />
          </div>

          <div className={'tsk-input-line'}>
            <label><FormattedMessage id={'project.settings.languageselector.shape.label'} /></label>

            <select value={project.language_selector_shape} onChange={(e) => {
              onLanguageSelectorSettingsUpdate({language_selector_shape: e.target.value});
            }}>
              <option value={'circle'}>{circleLanguageSelectorShapeTxt}</option>
              <option value={'rectangle'}>{rectangleLanguageSelectorShapeTxt}</option>
            </select>
          </div>

          <div className={'tsk-input-line'}>
            <label><FormattedMessage id={'project.settings.languageselector.displaytype.label'} /></label>

            <select value={project.language_selector_display_type} onChange={(e) => {
              onLanguageSelectorSettingsUpdate({language_selector_display_type: e.target.value});
            }}>
              <option value={'flag'}>{flagLanguageSelectorDisplayTypeTxt}</option>
              <option value={'country_code'}>{countryCodeLanguageSelectorDisplayTypeTxt}</option>
            </select>
          </div>
        </div>
        }
        <div className={'tsk-settings-block'}>
          <label><FormattedMessage id={'project.settings.fonts.label'} /></label>
          <ul className={'tsk-setting-list'}>
            {project.fonts.map((font, idx) => (
              <li style={{fontFamily: font.name}} key={!!font.id ? font.id : idx}>{font.name}</li>
            ))}
            <li className={'tsk-setting-list-new'}>
              <button className={'tsk-button tsk-link'} onClick={async () => {
                const fontUri = await fileUploadDialog({
                  accept: ["", "application/x-font-*", "application/font-*", "font/*", '.otf', '.ttf'],
                  uploadPath: "/font",
                  checksOnUpload: () => {
                    if (this.fontNameInputRef.current.value.length === 0) {
                      this.fontNameErrorRef.current.style.display = 'block';
                      return false;
                    }
                    return true;
                  },
                  additionalChildren: (
                    <div className={'tsk-dialog-content'}>
                      <div className={'tsk-input-line'}>
                        <label htmlFor={'font-name'}><FormattedMessage id={'project.settings.newfont.name.label'} />&nbsp;*</label>
                        <input type={"text"} name={'font-name'} ref={this.fontNameInputRef}/>
                      </div>
                      <div className={'tsk-error'} style={{display: 'none'}}  ref={this.fontNameErrorRef}>
                        <FormattedMessage id={'project.settings.newfont.name.required.error'} />
                      </div>
                    </div>
                  )
                }).catch(() => {});
                if(!!fontUri) {
                  onNewFont({
                    uri: fontUri,
                    name: this.fontNameInputRef.current.value
                  })
                }
              }}>
                <FontAwesomeIcon className={'tsk-bullet-point'} icon={'plus'} />
                <FormattedMessage id={'project.settings.fonts.new'} />
              </button>
            </li>
          </ul>
        </div>
        <div className={'tsk-settings-block'}>
          <label><FormattedMessage id={'project.settings.buttons.label'} /></label>
          <GlobalButtonSettings
            currentValue={{
              text_size: project.default_button_text_size,
              text_color: project.default_button_text_color,
              font: project.default_button_font,
              width_percentage: project.default_button_width_percentage,
              shape: project.default_button_shape,
              button_background_type: project.default_button_background_type,
              button_background_color: project.default_button_background_color,
              button_background_image_uri: project.default_button_background_image_uri,
            }}
            canSetToDefault={false}
            onChange={(btnSettings) => onNewButtonsSettings(btnSettings)}
            palette={project.color_palette}
            fonts={project.fonts}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(BaseProjectSettings);
