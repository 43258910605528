import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateTextCell ($input: UpdateTextCellInput!) {
      updateTextCell (input: $input) {
        id
        cell_type
        text_data
        line_height
        letter_spacing
        background_type
        background_image_uri
        background_video_uri
        background_color
        horizontal_align
        vertical_align
        i18n {
          language_code
          text_data
          background_image_uri
          background_video_uri
        }
      }
    }`,

  get: gql`
    query TextCell($id: ID!) {
      textCell(id: $id) {
        id
        text_data
        line_height
        letter_spacing
        background_type
        background_image_uri
        background_video_uri
        background_color
        horizontal_align
        vertical_align
        i18n {
          language_code
          text_data
          background_image_uri
          background_video_uri
        }
      }
    }`
}
