import React from 'react';
import { GithubPicker } from 'react-color'
import {withRouter} from "react-router-dom";

import '../style/ColorPicker.scss'

class PaletteColorPicker extends React.Component {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const { onChange, disableAlpha, palette, color } = this.props;

    if(!palette) return null;

    const buttonContent = (hasColor) => {
      if(hasColor) {
          return <span className={'tsk-color'} style={{background: color}} />
      }

      return <span>select a color</span>
    } 
    return (
      <div className={'tsk-colorpicker-container'}>
        <button className={'tsk-colorpicker-block'} onClick={ this.handleClick }>
          {buttonContent(color)}
        </button>
        { this.state.displayColorPicker &&
          <div className={'tsk-colorpicker-popover'}>
            <div className={'.tsk-colorpicker-cover'} onClick={ this.handleClose }/>
            <GithubPicker color={ color || palette[0] } colors={palette} onChange={ (newValue) => {
              this.setState({ displayColorPicker: false });
              onChange(newValue);
            } } disableAlpha={disableAlpha} />
          </div>
        }
      </div>
    )
  }
}

export default withRouter(PaletteColorPicker)