import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdatePlanCell($input: UpdatePlanCellInput!) {
      updatePlanCell(input: $input) {
        id
        cell_type
        border_form_color
        text_form_color
        btn_select_color
        background_color
        text_form_color
        background_btn_left
        background_btn_right
        color_text_left
        color_text_right
        plan_uri
        camera_x
        camera_y
        camera_z
      }
  }`,

  get: gql`
    query PlanCell($id: ID!) {
      planCell(id: $id) {
        id
        cell_type
        plan_uri
        border_form_color
        text_form_color
        btn_select_color
        camera_x
        camera_y
        camera_z
        background_color
        background_btn_left
        background_btn_right
        color_text_left
        color_text_right
        expos{
            id
            expo_number
            expo_name
            description
            expo_hall
            logo_uri
            website_uri
            categories{
              i18n{
                language_code
                name
              }
            }
            i18n{
              language_code
              expo_name
              description
            }
        }
      }
    }`
}
