import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateWheelCell ($input: UpdateWheelCellInput!) {
      updateWheelCell (input: $input) {
        id
        cell_type
        slots
        wheel_uri
        offset
        border_form_color
        background_form_color
        text_form_color
      }
    }`,

  get: gql`
    query WheelCell($id: ID!) {
      wheelCell(id: $id) {
        id
        cell_type
        slots
        wheel_uri
        offset
        border_form_color
        background_form_color
        text_form_color
        game{
          id
          prizes{
            id
            slot_index
          }
        }
        fields {
          id
          type
          name
          label
          required
          sort_order
          half_width
          visible
          options{
            id
            form_field_id
            name
            label
            i18n{
              language_code
              label
            }
          }
          i18n {
            language_code
            label
          }
        }
      }
    }`
}
